import { Spin, Card } from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { QRCode } from "@jackybaby/react-custom-qrcode";
import { BreadcrumbComponent } from "src/components";
import { images } from "src/assets";

export default function ShareQRcode() {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [imagesUser, setImages] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data: rPatitent }: any = await api.getProfile();
      if (rPatitent) {
        let images = rPatitent.userData.avatar;
        let url = "imedical://Referral";
        let id = rPatitent.userData.id;
        let name = rPatitent.userData.name;
        let member_type_id = rPatitent.userData.member_type.id;
        let valueMe = `${url}|${id}|${member_type_id}|${name}`;
        setImages(images);
        setValue(valueMe);
        setName(name);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const breadcrumbItems = [
    { path: "/", name: "Trang chủ" },
    { path: "#", name: "My QR Code" },
  ];

  return (
    <div className="admin-table">
      <BreadcrumbComponent items={breadcrumbItems} />

      <div className="flex-fill">
        <Spin spinning={loading}>
          <Card>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: 400,
                  overflow: "auto",
                  padding: "0 16px",
                  background:
                    "linear-gradient(140.91deg, #009ADD -43.24%, rgba(239, 250, 255, 0) 100.83%)",
                  color: "#0D044D",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: ".5rem",
                      marginTop: 50,
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={images.LogoImedical.logoQRcode}
                      alt="Ảnh Logo Imedical"
                      style={{
                        width: "100px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: ".5rem",
                      flexDirection: "column",
                    }}
                  >
                    <p style={{ fontSize: 18, fontWeight: 500 }}>{name}</p>
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "20%", width: "20%" }}
                      value={value}
                      bgColor={"#E9E9E9"}
                      logoImage={imagesUser}
                    />
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        marginTop: ".5rem",
                        // color: "#FFFFFF",
                      }}
                    >
                      Qr code giới thiệu
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Spin>
      </div>
    </div>
  );
}
