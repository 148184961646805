import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Skeleton,
  Tag,
  Upload,
  message,
} from "antd";
import { api } from "src/services";
import { useEffect, useState, useRef } from "react";
import {
  AvatarInformation,
  BillingInformation,
  BreadcrumbComponent,
  ContactInformation,
  PersonalInformation,
} from "src/components";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default function AccountInfomation() {
  const [userLogin, setUserLogin]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<any>(null);
  const [fileUpload, setFileUpload] = useState(null);

  useEffect(() => {
    fetchUserLogin();
  }, []);

  useEffect(() => {
    if (userLogin) {
      formRef.current.setFieldValue("name", userLogin.name);
      formRef.current.setFieldValue("gender", userLogin.gender);
      formRef.current.setFieldValue("specialist", userLogin.specialist);
      formRef.current.setFieldValue("dob", userLogin.dob);
      formRef.current.setFieldValue(
        "certificate_code",
        userLogin.certificate_code
      );
      formRef.current.setFieldValue("hospital_name", userLogin.hospital_name);
      formRef.current.setFieldValue("email", userLogin.email);
      formRef.current.setFieldValue("phone", userLogin.phone);
      formRef.current.setFieldValue("bank_number", userLogin.bank_number);
      formRef.current.setFieldValue("bank_username", userLogin.bank_username);
      formRef.current.setFieldValue("bank_name", userLogin.bank_name);
      formRef.current.setFieldValue("province_id", userLogin?.province?.matp);
      formRef.current.setFieldValue("district_id", userLogin?.district?.maqh);
      formRef.current.setFieldValue("ward_id", userLogin?.ward?.xaid);
      formRef.current.setFieldValue("short_address", userLogin?.short_address);
    }
  }, [userLogin]);

  const fetchUserLogin = async () => {
    try {
      setLoading(true);
      const response: any = await api.getProfile();
      if (response.error) {
        message.error(response.message);
      } else {
        setUserLogin(response.data.userData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("specialist", values.specialist);
    formData.append("dob", values.dob);
    formData.append("gender", values.gender);
    formData.append("certificate_code", values.certificate_code);
    formData.append("hospital_name", values.hospital_name);
    formData.append("province_id", values.province_id);
    formData.append("district_id", values.district_id);
    formData.append("ward_id", values.ward_id);
    formData.append("short_address", values.short_address);
    formData.append("email", values.email);
    formData.append("bank_name", values.bank_name);
    formData.append("bank_number", values.bank_number);
    formData.append("bank_username", values.bank_username);

    if (fileUpload) {
      formData.append("profile", fileUpload);
    }

    try {
      setLoading(true);
      const response: any = await api.updateProfile(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchUserLogin();
      setLoading(false);
    }
  };

  const onRemovefile = (file) => {
    setFileUpload(null);
  };

  const onBeforeUploadFile = (file) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF files!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must be smaller than 2MB!");
      return false;
    }
    setFileUpload(file);
    getBase64(file, (url) => {
      setLoading(false);
    });

    return false;
  };

  const breadcrumbItems = [
    { path: "/", name: "Trang chủ" },
    { path: "#", name: "Thông tin cá nhân" },
  ];

  return (
    <div className="personal-information">
      <BreadcrumbComponent items={breadcrumbItems} />
      <Form
        ref={formRef}
        // labelCol={{ span: 6 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleSubmit}
        className="form"
      >
        <Row gutter={[16, 16]}>
          <Col span={12} key={1}>
            <PersonalInformation />
            <BillingInformation />
          </Col>
          <Col span={12} key={2}>
            <Row gutter={[4, 4]}>
              <Col span={10}>
                <AvatarInformation
                  userLogin={userLogin}
                  fetchUserLogin={fetchUserLogin}
                />
              </Col>
              <Col span={10}>
                <Card
                  size="small"
                  title="Profile"
                  style={{ height: "100%" }}
                  className="wrapper_profile"
                >
                  <Skeleton loading={loading} active>
                    <Form.Item style={{ marginBottom: "0" }}>
                      {userLogin.profile &&
                        <Tag className="tag_profile" title={userLogin?.profile?.url}>
                          <a href={userLogin.profile.url} target="_blank">
                            {userLogin.profile.url}
                          </a>
                        </Tag>
                      }
                      <Upload
                        showUploadList={true}
                        maxCount={1}
                        beforeUpload={onBeforeUploadFile}
                        onRemove={onRemovefile}
                        accept=".pdf"
                      >
                        <Button>Chọn profile</Button>
                      </Upload>
                    </Form.Item>
                  </Skeleton>
                </Card>
              </Col>
              <Col className="wrapper_published" span={4}>
                <Card size="small" title="Xuất bản" style={{ height: "100%" }}>
                  <Form.Item style={{ marginBottom: "0" }}>
                    <Button type="primary" loading={loading} htmlType="submit">
                      Lưu
                    </Button>
                  </Form.Item>
                </Card>
              </Col>
            </Row>

            {userLogin?.province && (
              <ContactInformation
                formRef={formRef}
                province={userLogin?.province}
                district={userLogin?.district}
              />
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
}
