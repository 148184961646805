import { BankOutlined, CheckCircleOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Empty,
  Form,
  List,
  Pagination,
  Row,
  Tag,
  message,
} from "antd";
import { useRef, useState } from "react";
import {
  DeleteConfirmationModal,
  IconLocation,
  IconPhone,
  PaymentConfirmationModal,
  SpanPhone,
  CompletedConfirmationModal,
  ChangeScheduleBookingModal,
  RefundConfirmationModal,
  CreateAppointmentModal,
} from "src/components";
import { api } from "src/services";
import { utils } from "src/utils";

function ListPatientBooking({
  listPatientBooking,
  handleClickPatient,
  loading,
  fetchListPatitent,
  setLoading,
  activePatient,
  current,
  setCurent,
  pageSize,
  setPageSize,
  total,
}) {
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [isConfirmPaymentModal, setConfirmPaymentModal] = useState(false);
  const [isCompletedModal, setIsCompletedModal] = useState(false);
  const [isChangeModal, setChangeModal] = useState(false);
  const [isConfirmRefundModal, setConfirmRefundModal] = useState(false);
  const [formDelete] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [formCompleted] = Form.useForm();
  const formChange = useRef<any>(null);
  const [bookingUpdate, setBookingUpdate]: any = useState(false);
  const [bookingConfirmPayment, setBookingConfirmPayment]: any = useState('');
  const [bookingConfirmRefund, setBookingConfirmRefund]: any = useState('');
  const [isModalVisible, setModalVisible]: any = useState(false);

  const handleCancel = () => {
    setDeleteModal(false);
    setConfirmPaymentModal(false);
    setIsCompletedModal(false);
    setChangeModal(false);
    setConfirmRefundModal(false);
    setModalVisible(false);
  };

  const handleConfirmPayment = async (values: any) => {
    const formData = new FormData();
    formData.append('payment_confirm', values?.status);
    try {
      setLoading(true);
      const response: any = await api.updateBookingPayment(values.id, formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  const handleDelete = async (values: any) => {
    try {
      setLoading(true);
      const response: any = await api.updateBookingStatus(
        values.id,
        "canceled"
      );
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  const handleConfirmCompleted = async (values: any) => {
    try {
      setLoading(true);
      const response: any = await api.updateBookingStatus(
        values.id,
        "done"
      );
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  const handleClickShowModalChange = (booking: any) => {
    setChangeModal(true);
    setBookingUpdate(booking);
  }

  const handleClickShowModalConfirmRefund = (booking: any) => {
    setConfirmRefundModal(true);
    setBookingConfirmRefund(booking);
  }

  const handleClickShowModalConfirmPayment = (booking: any) => {
    setConfirmPaymentModal(true);
    formUpdate.setFieldsValue({
      id: booking.id,
    });
    setBookingConfirmPayment(booking);
  }

  const showModalCreateAppointMent = () => {
    setModalVisible(true);
  };

  return (
    <>
      <List
        locale={{
          emptyText: (
            <Empty description="Không có bệnh nhân đặt lịch khám trong ngày bạn chọn" />
          ),
        }}
        className="calendar-patient-list"
        loading={loading}
        header={
          <>
            <div className="font-bold">
              Danh sách lịch khám
            </div>
            <Button style={{ height: '100%' }} size="small" type="primary" onClick={showModalCreateAppointMent}>
              Tạo lịch hẹn tái khám
            </Button>
            {isModalVisible &&
              <CreateAppointmentModal
                isModalVisible={isModalVisible}
                handleCancel={handleCancel}
                fetchListPatitent={fetchListPatitent}
              />
            }
          </>
        }
        size="small"
        bordered
        footer={
          total > 10 ? (
            <Pagination
              style={{ float: "right" }}
              size="small"
              defaultCurrent={1}
              total={total}
              current={current}
              defaultPageSize={10}
              pageSize={pageSize}
              onChange={(current, pageSize) => {
                setCurent(current);
                setPageSize(pageSize);
              }}
            />
          ) : null
        }
        dataSource={listPatientBooking}
        renderItem={(item: any) => (
          <List.Item
            key={item?.member?.id}
            onClick={() => handleClickPatient(item?.member)}
            className={`patient-list-item ${activePatient && activePatient.id === item?.member?.id ? "active" : ""} ${item.is_disabled_booking ? "no-active" : ""}`}
            style={{ cursor: 'pointer' }}
          >
            <List.Item.Meta
              title={
                <>
                  <Avatar src={item?.member?.avatar} />
                  <div className="ant-list-item-meta-title-right">
                    <div className="patient-booking-name">
                      <span>
                        {item?.name}{" "}
                        <span> - {utils.calculateAge(item?.dob)} tuổi</span>
                      </span>
                      <div className="patient-booking-name-time">
                        <span>Stt khám: {item?.order_number}</span> -{" "}
                        <span>{item?.slot_start_time}</span>
                      </div>
                    </div>
                    <div className="patient-booking-name">
                      <span>
                        {item?.phone ? (
                          <>
                            <IconPhone />
                            <span className="patient-booking-detail-info-text ml-4px text-normal">
                              {<SpanPhone phone={item?.phone} />}
                            </span>
                          </>
                        ) : null}
                      </span>
                      <div className="patient-booking-name-time">
                        {item?.member?.address ? (
                          <div>
                            <IconLocation />
                            <span className="patient-booking-detail-info-text ml-4px text-normal">
                              {item?.member?.address}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              }
              description={
                <div className="patient-booking-detail">
                  <Row>
                    <Col span={24}>
                      <div className="status-booking">
                        <div className="status-booking-label">
                          Trạng thái lịch khám:
                        </div>
                        <div className="status-booking-value">
                          {item.status.value === "done" && (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="#87d068"
                              style={{ cursor: 'default' }}
                            >
                              Đã khám
                            </Tag>
                          )}

                          {item.status.value === "rescheduled" && (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="error"
                              style={{ cursor: 'default' }}
                            >
                              Đã lên lịch mới
                            </Tag>
                          )}

                          {['canceled', 'expired'].includes(item.status.value) && (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="#F02F34"
                              style={{ cursor: 'default' }}
                            >
                              {item?.status?.label}
                            </Tag>
                          )}

                          {['accept'].includes(item.status.value) && (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="processing"
                              style={{ cursor: 'default' }}
                            >
                              {item?.status?.label}
                            </Tag>
                          )}

                          {item.is_enable_accept && (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="#d76f30"
                              style={{ cursor: 'default' }}
                            >
                              Chờ xác nhận từ bệnh nhân
                            </Tag>
                          )}
                        </div>
                      </div>
                      <div className="status-booking">
                        <div className="status-booking-label">
                          Phương thức thanh toán:
                        </div>
                        <div className="status-booking-value">
                          <Tag
                            icon={<BankOutlined />}
                            color="#2db7f5"
                            style={{ cursor: 'default' }}
                          >
                            {item?.payment_method?.label}
                          </Tag>
                        </div>
                      </div>

                      {item.payment_method.value === "banking" && (
                        <div className="status-booking">
                          <div className="status-booking-label">Trạng thái thanh toán:</div>
                          <div className="status-booking-value">
                            {item.payment_confirm.value === "paid" && (
                              <Tag
                                icon={<CheckCircleOutlined />}
                                color="success"
                                style={{ cursor: 'default' }}
                              >
                                {item.payment_confirm.label}
                              </Tag>
                            )}

                            {item.payment_confirm.value === "unpaid" && (
                              <Tag color="#f50"
                                style={{ cursor: 'default' }}
                              >
                                {item.payment_confirm.label}
                              </Tag>
                            )}

                            {item.payment_confirm.value === "waiting_accept" && (
                              <Tag
                                icon={<CheckCircleOutlined />}
                                color="orange"
                                style={{ cursor: 'default' }}
                              >
                                {item.payment_confirm.label}
                              </Tag>
                            )}

                            {item.payment_confirm.value === "refunded" && (
                              <Tag
                                icon={<CheckCircleOutlined />}
                                color="#87d068"
                                style={{ cursor: 'default' }}
                              >
                                {item.payment_confirm.label}
                              </Tag>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="status-booking">
                        <div className="status-booking-label">
                          Hành động:
                        </div>
                        <div className="status-booking-value">
                          {item.is_enable_complete_examination && (
                            <Tag
                              color="#108ee9"
                              icon={<CheckCircleOutlined />}
                              onClick={() => {
                                setIsCompletedModal(true);
                                formCompleted.setFieldsValue({
                                  id: item.id,
                                });
                              }}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              Hoàn thành khám
                            </Tag>
                          )}

                          {item.is_enable_change_schedule && (
                            <Tag
                              color="#108ee9"
                              onClick={() => handleClickShowModalChange(item)}
                              style={{
                                cursor: 'pointer'
                              }}
                              icon={<EditOutlined />}
                            >
                              Thay đổi lịch
                            </Tag>
                          )}
                          {item.is_enable_cancel_by_doctor && (
                            <Tag
                              icon={<CloseOutlined />}
                              color="#cd201f"
                              style={{
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setDeleteModal(true);
                                formDelete.setFieldsValue({
                                  id: item.id,
                                });
                              }}
                            >
                              Hủy lịch khám
                            </Tag>
                          )}

                          {item.is_enable_confirm_payment && (
                            <Tag
                              color="#2db7f5"
                              onClick={() => handleClickShowModalConfirmPayment(item)}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              Xác nhận thanh toán
                            </Tag>
                          )}

                          {item.is_enable_confirm_refunded && (
                            <Tag
                              color="#108ee9"
                              onClick={() => handleClickShowModalConfirmRefund(item)}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              Xác nhận đã hoàn tiền
                            </Tag>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            />
          </List.Item>
        )}
      />
      <DeleteConfirmationModal
        isVisible={isDeleteModal}
        onCancel={handleCancel}
        onDelete={handleDelete}
        loading={loading}
        form={formDelete}
      />

      <CompletedConfirmationModal
        isVisible={isCompletedModal}
        onCancel={handleCancel}
        onFinishUpdate={handleConfirmCompleted}
        loading={false}
        form={formCompleted}
      />
      {
        bookingUpdate &&
        <ChangeScheduleBookingModal
          isOpenModal={isChangeModal}
          handleCancel={handleCancel}
          formChange={formChange}
          booking={bookingUpdate}
          patientInfo={bookingUpdate?.member}
          fetchListPatitent={fetchListPatitent}
        />
      }

      {
        bookingConfirmPayment &&
        (
          <PaymentConfirmationModal
            isVisible={isConfirmPaymentModal}
            handleCancel={handleCancel}
            onFinishConfirmPayment={handleConfirmPayment}
            loading={loading}
            form={formUpdate}
            booking={bookingConfirmPayment}
          />
        )
      }

      {
        bookingConfirmRefund &&
        (
          <RefundConfirmationModal
            isVisible={isConfirmRefundModal}
            handleCancel={handleCancel}
            booking={bookingConfirmRefund}
            fetchListPatitent={fetchListPatitent}
          />
        )
      }
    </>
  );
}

export default ListPatientBooking;
