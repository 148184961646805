import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useMemo,
} from "react";
import { api } from "src/services";
import { utils } from "src/utils";
import CardDisease from "../shared/Card/CardDisease";
import { debounce } from "lodash";
import CardSave from "../shared/Card/CardSave";

function EditSamplePrescriptionModal({
  isModalEditVisible,
  handleCancel,
  form,
  fetchSamplePrescription,
  samplePrescriptionDetail,
}) {
  let totalPrice = 0;
  let totalCostPrice = 0;
  const minCurrentScroll = 30;
  const [loading, setLoading] = useState(false);
  const [medicines, setMedicines] = useState([]);
  const [diseases, setDisease] = useState([]);
  const [loadingDisease, setLoadingDisease] = useState(false);
  const [searchQueryDisease, setSearchQueryDisease] = useState("");
  const [currentScroll, setCurrentScroll] = useState(minCurrentScroll);
  const [total, setTotal] = useState(0);
  const [rows, initRow] = useState([]);
  const [totalAll, setTotalPrice] = useState(0);
  const [totalCostAll, setTotalCostPrice] = useState(0);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const formRef = useRef<any>(null);
  const debouncedSearch = debounce(setSearchQueryDisease, 300);
  var arr = [];

  const fetchDisease = async () => {
    try {
      setLoadingDisease(true);
      const { data: rData, meta }: any = await api.getTestAllDisease(
        `${currentScroll}`,
        searchQueryDisease
      );
      if (rData) {
        setDisease(rData);
        setTotal(meta.total);
      } else {
        setDisease([]);
        setCurrentScroll(minCurrentScroll);
        setTotal(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDisease(false);
    }
  };

  const initDataEdit = async () => {
    try {
      setLoading(true);
      const { data: rData }: any = await api.getAllMedicine();

      if (rData) {
        setMedicines(rData);
        samplePrescriptionDetail.prescriptions.map((item, index) => {
          if (!(index in arr)) {
            arr[index] = {};
          }

          item.map((it, index1) => {
            if (index1 === 8) {
              Object.assign(arr[index], { medicineName: it });
            } else {
              Object.assign(arr[index], { [it.key]: it.value });
            }
          });
        });

        samplePrescriptionDetail.prescriptions.map((item, index) => {
          rData.forEach((medicine) => {
            if (medicine.id === arr[index].medicine) {
              arr[index]["price"] =
                medicine.cost_price * Number(arr[index]["quantity"]);
              arr[index]["costPrice"] =
                medicine.cost_of_goods_sold * Number(arr[index]["quantity"]);
              totalPrice += Number(arr[index]["price"]);
            }
          });
        });

        initRow(arr);
        setTotalPrice(totalPrice);
      } else {
        setMedicines([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initDataEdit();

    form.setFieldsValue({
      name: samplePrescriptionDetail.name,
      note: samplePrescriptionDetail.note ?? '',
    });

    setSelectedDiseases(samplePrescriptionDetail.type_disease_ids);
  }, []);

  useEffect(() => {
    fetchDisease();
    if (currentScroll) {
      setLoadingDisease(true);
      setTimeout(() => {
        setLoadingDisease(false);
      }, 1000);
    }
  }, [searchQueryDisease, currentScroll]);

  const handleSelectedDiseases = useCallback((value) => {
    setSelectedDiseases(value);
  }, []);

  const handlePopupScroll = useCallback(
    (e) => {
      const node = e.target;
      const bottom =
        node.scrollHeight - Math.ceil(node.scrollTop) === node.clientHeight;
      if (bottom) {
        setLoadingDisease(true);
        setCurrentScroll((prev) => prev + minCurrentScroll);
      }
    },
    [currentScroll]
  );

  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
  };

  // Thêm mới thuốc sẽ tính tổng tiền và tổng giá vốn theo số lượng thuốc
  const tableAddRowPrescription = (index) => {
    const dataRow = [...rows];
    medicines.forEach((medicine) => {
      if (medicine.id === dataRow[index].medicine) {
        dataRow[index]["price"] =
          medicine.cost_price * Number(dataRow[index]["quantity"]);
        dataRow[index]["costPrice"] =
          medicine.cost_of_goods_sold * Number(dataRow[index]["quantity"]);
      }
    });
    dataRow.forEach((item) => {
      totalPrice += Number(item.price);
      totalCostPrice += Number(item.costPrice);
      setTotalPrice(totalPrice);
      setTotalCostPrice(totalCostPrice);
    });

    initRow(dataRow);
  };

  // Xóa thuốc đã chọn sét lại tổng tiền và tổng vốn
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
    if (dataRow.length === 0) {
      totalPrice = 0;
    }
    dataRow.forEach((item) => {
      totalPrice += item.price;
      totalCostPrice += item.costPrice;
    });
    setTotalPrice(totalPrice);
    setTotalCostPrice(totalCostPrice);
  };

  // Chọn thuốc sẽ apend cách dùng thuốc và lưu lại id thuốc theo tên thuốc đã chọn
  const onChangeMedicine = (i, value, event) => {
    let isExistsMedicine = rows.find((item) => item?.medicine === value);
    if (isExistsMedicine) {
      message.error(`Thuốc ${isExistsMedicine?.medicineName} đã được chọn`);
      return;
    }
    const data = [...rows];
    data[i][`medicineName`] = event?.children;
    medicines.forEach((medicine) => {
      if (medicine.id === value) {
        data[i]["use"] = medicine.drug_route;
        data[i]["medicine"] = medicine?.id;
      }
    });
    initRow(data);
  };

  const addRowTable = () => {
    const data = {
      medicineName: "",
      use: "",
      quantity: "",
      days: "",
      morning: "",
      noon: "",
      afternoon: "",
      evening: "",
      price: "",
    };
    initRow([...rows, data]);
  };

  const TableRows = ({ rows, tableRowRemove, onValUpdate }) => {
    return rows.map((rowsData, index) => {
      const {
        medicineName,
        use,
        quantity,
        days,
        morning,
        noon,
        afternoon,
        evening,
        price,
      } = rowsData;

      return (
        <tr key={index}>
          <td>
            <input
              type="text"
              value={index + 1}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <Select
              showSearch
              loading={loading}
              value={medicineName}
              onSelect={(value, event) => onChangeMedicine(index, value, event)}
            >
              {medicines.map((medicine) => (
                <Option key={medicine?.id} value={medicine?.id}>
                  {medicine?.name}
                </Option>
              ))}
            </Select>
          </td>
          <td>
            <input
              type="text"
              value={use}
              onChange={(event) => onValUpdate(index, event)}
              name="use"
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="quantity"
              defaultValue={quantity}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="days"
              defaultValue={days}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="noon"
              defaultValue={noon}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="morning"
              defaultValue={morning}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="afternoon"
              defaultValue={afternoon}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="evening"
              defaultValue={evening}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td className="input-event-none">
            <input
              type="text"
              value={utils.formatMoney(price)}
              name="price"
              className="form-control"
            />
          </td>
          <td className="addRow">
            <button onClick={() => tableAddRowPrescription(index)}>
              <CheckOutlined />
            </button>
          </td>
          <td>
            <button
              className="btn btn-dark"
              onClick={() => tableRowRemove(index)}
            >
              <DeleteOutlined />
            </button>
          </td>
        </tr>
      );
    });
  };

  const handleEditSamplePrescription = async (filedValues: any) => {
    if (!selectedDiseases.length) {
      message.error("Vui lòng chọn chẩn đoán");
      return;
    }
    if (!rows.length) {
      message.error("Vui lòng chọn chỉ định dùng thuốc");
      return;
    } else {
      let validateMedicine = false;
      rows.forEach((item) => {
        if (
          !item.afternoon ||
          !item.costPrice ||
          !item.days ||
          !item.evening ||
          !item.medicine ||
          !item.medicineName ||
          !item.morning ||
          !item.noon ||
          !item.price ||
          !item.quantity ||
          !item.use
        ) {
          validateMedicine = true;
          return;
        }
      });
      if (validateMedicine) {
        message.error("Vui lòng nhập đầy đủ thông tin cho thuốc");
        return;
      }
    }

    var rowsCover = rows.map((el: any) => {
      return Object.entries(el)
        .map(([key, value]) => {
          if (
            [
              "medicine",
              "use",
              "quantity",
              "days",
              "morning",
              "noon",
              "afternoon",
              "evening",
              "price",
            ].includes(key)
          ) {
            return { key, value };
          }
        })
        .filter((el) => el !== undefined);
    });

    rowsCover.map((item: any, index) => {
      if (rowsCover[index].findIndex((ob) => ob.key === "medicine") !== 0) {
        //move to the top object have key medicine
        let indexMedicine = rowsCover[index].findIndex(
          (ob) => ob.key === "medicine"
        );
        rowsCover[index].unshift(rowsCover[index][indexMedicine]);
        let tmp = rowsCover[index][8];
        rowsCover[index][8] = rowsCover[index][9];
        rowsCover[index][9] = tmp;
      } else {
        let tmp = rowsCover[index][8];
        rowsCover[index][8] = { key: "", value: "" };
        rowsCover[index][9] = tmp;
      }
    });

    const dataSubmit = {
      name: filedValues.name,
      note: filedValues.note ?? '',
      type_disease: selectedDiseases.map((el: any) => el),
      prescriptions: rowsCover,
      total: totalAll || 0,
      profit: totalAll - totalCostAll || 0,
    };

    try {
      setLoading(true);
      const response: any = await api.editSamplePrescription(
        samplePrescriptionDetail.id,
        dataSubmit
      );

      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
        setCurrentScroll(minCurrentScroll);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      setLoading(false);
      fetchSamplePrescription();
      form.resetFields();
      formRef.current.resetFields();
      setSelectedDiseases([]);
      handleCancel();
      initRow([]);
      setTotalPrice(0);
      setTotalCostPrice(0);
      setCurrentScroll(minCurrentScroll);
    }
  };

  return (
    <Modal
      title="Chỉnh sửa toa thuốc mẫu"
      open={isModalEditVisible}
      footer={null}
      onCancel={handleCancel}
      width={1400}
      centered
      className="sample-prescription-add"
      destroyOnClose={true}
    >
      <Form
        onFinish={handleEditSamplePrescription}
        form={form}
        ref={formRef}
        className="form-add-prescription"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              className="card-date-note"
              size="small"
              style={{
                marginBottom: 16,
              }}
            >
              <Form.Item
                label="Tên toa thuốc mẫu"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Tên toa thuốc mẫu không được để trống",
                  },
                ]}
              >
                <Input defaultValue={samplePrescriptionDetail.name} />
              </Form.Item>
              <Form.Item
                label="Ghi chú"
                name="note"
              >
                <TextArea
                  showCount
                  maxLength={500}
                  rows={4}
                  placeholder="Ghi chú"
                  defaultValue={samplePrescriptionDetail?.note}
                />
              </Form.Item>
            </Card>
          </Col>
          <Col span={12}>
            <CardDisease
              loading={loading}
              loadingDisease={loadingDisease}
              setSearchQueryDisease={debouncedSearch}
              diseaseCategories={diseases}
              selectedDiseases={handlePopupScroll}
              handleSelectedDiseases={handleSelectedDiseases}
              total={total}
              samplePrescriptionDetail={samplePrescriptionDetail}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Skeleton loading={loading}>
              <div>
                <div className="addPrescription" onClick={addRowTable}>
                  Thêm mới thuốc
                </div>
                <table className="table table-striped table_add_prescription">
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên thuốc</th>
                      <th>Cách dùng</th>
                      <th>Số lượng</th>
                      <th>Ngày</th>
                      <th>Sáng</th>
                      <th>Trưa</th>
                      <th>Chiều</th>
                      <th>Tối</th>
                      <th>Đơn giá</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableRows
                      rows={rows}
                      tableRowRemove={tableRowRemove}
                      onValUpdate={onValUpdate}
                    />
                  </tbody>
                </table>

                <div className="d-flex addPrescription__wapper">
                  <div className="addPrescription " onClick={addRowTable}>
                    Thêm mới thuốc
                  </div>
                </div>
                <div className="totalPrice">
                  <h4>Tổng tiền:</h4>
                  <span>{utils.formatMoney(totalAll)} đ</span>
                </div>
              </div>
            </Skeleton>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CardSave loading={loading} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default EditSamplePrescriptionModal;
