import { ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Modal,
  Space,
  Table,
  Upload,
  message,
  Image,
} from "antd";
import { useEffect, useState } from "react";
import {
  BreadcrumbComponent,
  IconDelete,
  ModalDelete,
  ModalUpdate,
  OnDeleteButton,
} from "src/components";
import { api } from "src/services";
import { HeadLetterProperties } from "src/types";

function HeadLetter() {
  const [dataHeadLetter, setDataHeadLetters]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);
  const [formDelete] = Form.useForm();
  const [formChangeStatus] = Form.useForm();
  const { confirm } = Modal;
  let formData = new FormData();

  const fetchHeadLetter = async () => {
    try {
      setLoading(true);
      const { data: rData }: { data: HeadLetterProperties[] } =
        await api.getHeadletter();
      if (rData) {
        let signatureArr = rData.map((el: any) => ({
          key: el.id,
          ...el,
        }));
        setDataHeadLetters(signatureArr);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchHeadLetter();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      key: "image",
      render: (text: string, record: any) => (
        <Image
          preview={{
            mask: <EyeOutlined style={{ color: "white" }} />,
            maskClassName: "hide-preview-text",
          }}
          src={record.image.url}
          width={40}
          height={35}
          alt="Avatar"
        />
      ),
    },
    {
      title: "Đang sử dụng",
      dataIndex: "active",
      key: "active",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Checkbox
            checked={record.active}
            onClick={() => {
              setModalChangeStatus(true);
              formChangeStatus.setFieldsValue({
                id: record.id,
              });
            }}
          />
        </Space>
      ),
    },
    {
      title: "Ngày",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Hoạt động",
      render: (text: string, record: any) => (
        <IconDelete
          onClick={() => {
            setModalDelete(true);
            formDelete.setFieldsValue({
              id: record.id,
              status: record.status,
            });
          }}
        />
      ),
    },
  ];

  const handleCancel = () => {
    setModalDelete(false);
    setModalChangeStatus(false);
  };

  const handleSubmit = async () => {
    if (fileUpload === null) {
      return message.error("Vui lòng chọn lại file ảnh");
    }
    try {
      setLoading(true);
      formData.append("image", fileUpload);
      const response: any = await api.createHeadletter(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      setFileUpload(null);
      fetchHeadLetter();
      setLoading(false);
    }
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteHeadletter(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      fetchHeadLetter();
      handleCancel();
      setLoading(false);
    }
  };

  const onBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      setFileUpload(null);
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }
    setFileUpload(file);
    return false;
  };

  const handleChangeStatus = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.updateHeadletter(values.id);

      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      handleCancel();
      fetchHeadLetter();
      setLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
  };

  const onConfirmDelete = () => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa ?",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          setLoading(true);
          const response: any = await api.deleteAllHeadletter(selectedRows);
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          console.log(error);
          message.success(error.message);
        } finally {
          fetchHeadLetter();
          setLoading(false);
        }
        setTimeout(() => {
          setSelectedRows([]);
        }, 2000);
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };

  const breadcrumbItems = [
    { path: "/", name: "Trang chủ" },
    { path: "#", name: "Cài đặt HeadLetter" },
  ];

  return (
    <div className="admin-table">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: ".5rem",
          }}
        >
          <div>
            <OnDeleteButton
              onClick={onConfirmDelete}
              disabled={selectedRows.length === 0}
              title={"Xóa"}
            />
          </div>
          <Upload
            onChange={handleSubmit}
            beforeUpload={onBeforeUpload}
            showUploadList={null}
            accept=".jpg, .jpeg, .png"
          >
            <Button type="primary">Upload File</Button>
          </Upload>
        </div>
        <Table
          rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={dataHeadLetter}
          loading={loading}
        />
      </div>
      <ModalDelete
        open={modalDelete}
        cancel={handleCancel}
        form={formDelete}
        handleSubmit={handleDelete}
        loading={loading}
        title={"Xóa chữ ký"}
      />
      <ModalUpdate
        open={modalChangeStatus}
        cancel={handleCancel}
        form={formChangeStatus}
        handleSubmit={handleChangeStatus}
        loading={loading}
        title={"Thay đổi chữ ký"}
      />
    </div>
  );
}
export default HeadLetter;
