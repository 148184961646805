import {
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Skeleton,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { api } from "src/services";
import { useHistory, useParams } from "react-router-dom";
import { UserProperty, PacketTestType } from "src/types";
import { sum } from "lodash";
import { isEmpty } from "lodash";
import {
  BreadcrumbComponent,
  CardSave,
  TestPackage,
  TestAt,
  TestTypeButtons,
  UploadSection,
  PersonalInfo,
} from "src/components";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";

function PageOrderPatientTest() {
  const { id }: any = useParams();
  const [patientInfo, setPatientInfo] = useState<UserProperty | null>(null);
  const [packetTests, setPacketTests] = useState<PacketTestType[]>([]);
  const [samplingPackages, setSamplingPackages]: any = useState([]);
  const [testCategories, setTestCategories]: any = useState([]);
  const [isImageConfirmationNeeded, setIsImageConfirmationNeeded] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<any>(null);
  const history = useHistory();
  const [selectedTests, setSelectedTests] = useState([]);
  const [fileUploads, setFileUploads] = useState([]);
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const [numberOfCheckedFiles, setNumberOfCheckedFiles] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [typeTextErrorMessage, setTypeTextErrorMessage] = useState("");
  const formData = new FormData();
  const [searchQuery, setSearchQuery]: any = useState("");
  const [resultCheck, setResultCheck] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const { name, gender, dob, phone, email, address } = patientInfo || {};
  const [currentTime] = useState(moment().format("YYYY-MM-DD"));
  const [loadingOrdertest, setLoadingOrdertest] = useState(false);

  const fetchMyPatient = async () => {
    try {
      setLoading(true);
      const { data: rData }: any = await api.getMemberInfo(id);
      if (rData) {
        setPatientInfo(rData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTestPriority = async () => {
    try {
      const { data: rData }: { data: PacketTestType[] } =
        await api.getTestPriority();
      if (rData) {
        setPacketTests(rData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTestPlace = async () => {
    try {
      const { data: rData }: { data: PacketTestType[] } =
        await api.getTestPlace();
      if (rData) {
        setSamplingPackages(rData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTestCategories = async () => {
    try {
      const { data: rData }: any = await api.getAllTestCategories(searchQuery);
      if (rData) {
        setTestCategories(rData);
        setData(rData);
        setFilteredData(rData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [provinceId]: any = useState(0);
  const [districtId]: any = useState(0);
  const [setListProvinces]: any = useState([]);
  const [setListDistrict]: any = useState([]);
  const [setListWard]: any = useState([]);

  const fetchProvinces = async (provinceId?: 0, districtId?: 0) => {
    try {
      // setLoading(true);
      const { data: rData }: any = await api.listOfProvinces(
        provinceId,
        districtId
      );
      if (rData) {
        setListProvinces(rData.province);
        setListDistrict(rData.district);
        setListWard(rData.ward);
      } else {
        setListProvinces([]);
        setListProvinces([]);
        setListDistrict([]);
        setListWard([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchProvinces(provinceId, districtId);
  }, [provinceId, districtId]);

  useEffect(() => {
    if (patientInfo) {
      formRef.current.setFieldsValue({
        member_name: name,
        member_gender: gender,
        member_dob: dob,
        member_phone: phone,
        member_email: email,
        member_address: address,
      });
    }
  }, [patientInfo, name, gender, dob, phone, email, address]);

  useEffect(() => {
    fetchTestPriority();
    fetchTestPlace();
    fetchMyPatient();
  }, []);

  useEffect(() => {
    setTestCategories([]);
    fetchTestCategories();
    if (searchQuery) {
      setLoadingOrdertest(true);
      setTimeout(() => {
        setLoadingOrdertest(false);
      }, 2000);
    } else {
      setLoadingOrdertest(true);
      setTimeout(() => {
        setLoadingOrdertest(false);
      }, 2000);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (id) {
      // history.push(`/my-patient/order-patient-test/${id}`);
    } else {
      history.push("/order-patient-test/create");
    }
  }, [id, history]);

  const handleCheckboxChange = (checkedValues) => {
    const isChecked = selectedTests.includes(checkedValues.id);
    if (isChecked === true) {
      const removerItem = resultCheck.filter(
        (item) => item.id !== checkedValues.id
      );
      setResultCheck(removerItem);
    } else {
      setResultCheck([...resultCheck, checkedValues]);
    }
    const newSelected = isChecked
      ? selectedTests.filter(
          (selectedTest) => selectedTest !== checkedValues.id
        )
      : [...selectedTests, checkedValues.id];

    setSelectedTests(newSelected);
    setTypeTextErrorMessage("");
    setIsAnyCheckboxChecked(resultCheck.length > 0);
  };

  const handleSubmit = async (values) => {
    if (!isImageConfirmationNeeded) {
      if (!isAnyCheckboxChecked) {
        setTypeTextErrorMessage("Vui lòng chọn ít nhất một loại xét nghiệm");
        return;
      }

      selectedTests.forEach((type_test, index) => {
        formData.append(`type_test[${index}]`, type_test);
      });
      setFileUploads(null);
    } else if (numberOfCheckedFiles < 1) {
      setErrorMessage("Vui lòng chọn ít nhất một hình ảnh xét nghiệm");
      return;
    } else {
      fileUploads.forEach((file, index) => {
        formData.append(`images[${index}]`, file.file);
      });

      values.member_gender = "";
      values.member_dob = currentTime;
      values.member_email = "";
    }

    values.type_test = selectedTests;

    const total_test_type_price = testCategories
      .flatMap((item) => item.type_tests)
      .filter((value) => selectedTests.includes(value.id))
      .reduce((accumulator, value) => accumulator + value.price, 0);

    const selectedPacket = packetTests.find(
      (value) => value.id === values.packet_test_id
    );
    const total_test_package_price = selectedPacket ? selectedPacket.price : 0;

    const selectedSamplingPackage = samplingPackages.find(
      (value) => value.id === values.sampling_package_id
    );
    const total_sampling_package_price = selectedSamplingPackage
      ? selectedSamplingPackage.price
      : 0;

    values.total = sum([
      total_test_type_price,
      total_test_package_price,
      total_sampling_package_price,
    ]);

    if (id) {
      formData.append("patient_id", id);
    }

    const fieldsToAppend = {
      member_address: address,
      member_dob: dob,
      member_email: email,
      member_gender: gender,
      member_name: name,
      member_phone: phone,
      packet_test_id: values.packet_test_id,
      sampling_package_id: values.sampling_package_id,
      total: values.total,
      province_id: values.province_id,
      district_id: values.district_id,
      ward_id: values.ward_id,
      short_address: values.short_address,
    };

    for (const [field, fieldValue] of Object.entries(fieldsToAppend)) {
      if (fieldValue !== undefined) {
        formData.append(field, fieldValue);
      }
    }
    try {
      setLoading(true);
      const response: any = await api.createOrderTest(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
        history.push("/my-patient");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }

    const fileObject = {
      name: file.name,
      size: file.size,
      url: null, // You can set the URL here once it's available
      file: file, // Store the file object if needed for later use
    };
    // Update fileUpload state by concatenating the new fileObject with the existing files
    setFileUploads((prevFiles) => prevFiles.concat(fileObject));

    getBase64(file, (url) => {
      fileObject.url = url; // Set the URL for the uploaded file
    });
    return false;
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onRemove = (file) => {
    const updatedFiles = fileUploads.filter((item) => item.name !== file.name);
    setFileUploads(updatedFiles);
  };

  // search
  const handleSearch = () => {
    // const filteredResults = data.filter((parent) => {
    //   const matchedChildren = parent.type_tests.filter((child) =>
    //     child.name.toLowerCase().includes(filterValue.toLowerCase())
    //   );
    //   return matchedChildren.length > 0;
    // });
    // setFilteredData(filteredResults);

    // New search
    const filteredResults = data.map((parent) => {
      // Lọc các con khớp với filterValue
      const matchedChildren = parent.type_tests.filter((child) =>
        child.name.toLowerCase().includes(filterValue.toLowerCase())
      );

      // Nếu có con khớp, trả về cha với danh sách con khớp
      if (matchedChildren.length > 0) {
        return { ...parent, type_tests: matchedChildren };
      }

      // Nếu không có con khớp, bỏ qua phần tử cha này
      return null;
    }).filter((parent) => parent !== null); // Loại bỏ các mục cha không khớp
  
    setFilteredData(filteredResults);
  };
  useEffect(() => {
    handleSearch();
  }, [filterValue]);

  // const handleSearch = _.debounce(function (e: any) {
  //   setSearchQuery(e.target.value);
  // }, 300);

  const breadcrumbItems = [
    { path: "/", name: "Trang chủ" },
    { path: "#", name: "Chỉ định xét nghiệm" },
  ];
  const handleRemove = (e) => {
    const removerItem = resultCheck.filter((item, i) => item.id !== e);
    setResultCheck(removerItem);
  };
  const { Panel } = Collapse;
  return (
    <div className="admin-table OrderTest">
      <BreadcrumbComponent items={breadcrumbItems} />
      <div className="flex-fill">
        <Form ref={formRef} onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={15}>
              <Card
                className="card-order-left"
                style={{
                  height: "unset",
                  marginBottom: "1rem",
                  padding: "2rem 1rem 0 1rem",
                }}
              >
                <div className="order-content order-content_wapper package_test_wrapper">
                  {packetTests.length > 0 && (
                    <TestPackage
                      label="Gói xét nghiệm"
                      name="packet_test_id"
                      rules={[
                        {
                          required: true,
                          message: "Gói xét nghiệm không được bỏ trống",
                        },
                      ]}
                      data={packetTests}
                    />
                  )}
                  <div className="location_test">
                    {samplingPackages.length > 0 && (
                      <TestAt
                        label="Xét nghiệm tại : "
                        name="sampling_package_id"
                        rules={[
                          {
                            required: true,
                            message: "Địa điểm xét nghiệm không được bỏ trống",
                          },
                        ]}
                        data={samplingPackages}
                      />
                    )}
                  </div>
                </div>
              </Card>

              <Card
                size="small"
                style={{
                  marginBottom: 16,
                  height: "unset",
                  padding: "1rem 1rem 0 1rem",
                }}
                loading={loading}
              >
                <TestTypeButtons
                  isImageConfirmationNeeded={isImageConfirmationNeeded}
                  setIsImageConfirmationNeeded={setIsImageConfirmationNeeded}
                  setSearchQuery={setSearchQuery}
                  setLoadingOrdertest={setLoadingOrdertest}
                />
                <div className="info_patient">
                  <PersonalInfo patientInfo={patientInfo} />
                </div>
                <UploadSection
                  isImageConfirmationNeeded={isImageConfirmationNeeded}
                  onBeforeUpload={onBeforeUpload}
                  setCheckFile={setNumberOfCheckedFiles}
                  messageError={errorMessage}
                  onRemove={onRemove}
                />
              </Card>
              <div className="button_save">
                <CardSave loading={loading} />
              </div>
            </Col>
            <Col span={9}>
              <Card
                title="Loại xét nghiệm"
                style={{
                  marginBottom: 16,
                  height: "unset",
                }}
                size="small"
              >
                <Form.Item>
                  <Input
                    placeholder="Tìm kiếm danh mục"
                    onChange={(e) => setFilterValue(e.target.value)}
                  />
                </Form.Item>

                <Form.Item>
                  <Skeleton loading={loadingOrdertest} active>
                    <div className="scroll-checkbox">
                      {/* <Collapse> */}
                        {filteredData.map((cat, index) => (
                          // <Panel header={cat.name} key={index}>
                            <Row>
                              {cat.type_tests.map((test) => (
                                <Col
                                  key={test.id}
                                  span={24}
                                  style={{ marginBottom: 5 }}
                                >
                                  <Checkbox
                                    // checked={checkId.includes(test.id)}
                                    checked={resultCheck.some(
                                      (item) => item.id === test.id
                                    )}
                                    onClick={() => handleCheckboxChange(test)}
                                  >
                                    {`${
                                      test.name
                                    } - ${test.price.toLocaleString(
                                      "vi-VN"
                                    )} đ`}
                                  </Checkbox>
                                </Col>
                              ))}
                            </Row>
                          // </Panel>
                        ))}
                      {/* </Collapse> */}
                    </div>
                  </Skeleton>
                  <label style={{ color: "red" }}>
                    {!isEmpty(typeTextErrorMessage) ? typeTextErrorMessage : ""}{" "}
                  </label>
                </Form.Item>
              </Card>
              <Card style={{ height: "unset", marginBottom: "1rem" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  Loại xét nghiệm được chọn
                </div>
                <div>
                  <ul
                    style={{
                      maxHeight: "100px",
                      overflow: "auto",
                    }}
                  >
                    {resultCheck.map((test, index) => (
                      <li className="result__test-type" key={test.id}>
                        <span
                          onClick={() => handleRemove(test.id)}
                          className="result__test-type-icon"
                        >
                          <CloseOutlined />
                        </span>
                        {`${test.name} - ${test.price.toLocaleString(
                          "vi-VN"
                        )} đ`}
                      </li>
                    ))}
                  </ul>
                </div>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default PageOrderPatientTest;
