import { FilePdfOutlined } from "@ant-design/icons";
import { List, Tag, Card, Skeleton, Image, Avatar, Row, Col } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { utils } from "src/utils";

function ServiceResultModal({ number, patientId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState({});

  useEffect(() => {
    fetchData();
  }, [number, patientId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: rPatitent }: any = await api.getServiceResultPatient(patientId);
      if (rPatitent) {
        setData(rPatitent);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-table OrderTest">
      <div className="flex-fill">
        <div>
          <Card
            title="Kết quả"
            size="small"
            loading={loading}
            style={{ marginBottom: 16 }}
          >
            <Skeleton loading={loading}>
              {data.length > 0 ? (
                <List
                  dataSource={data}
                  className="list-detail-subclinical"
                  renderItem={(item: any) => (
                    <List.Item className="list-detail-subclinical-item">
                      <div className="list-detail-subclinical-file">
                            <div className="tag-subclinical">
                              <a href={item.url} target="__blank" className="subclinical-box">
                                <Row align="middle">
                                  <Col span={6}>
                                    <FilePdfOutlined />
                                  </Col>
                                  <Col span={10}>
                                    {
                                      item?.doctor ? 
                                      <span>
                                        <Avatar src={item?.doctor?.avatar}/> {item?.doctor?.name}
                                      </span> :
                                      <span>
                                        iMedical
                                      </span> 
                                    }
                                  </Col>
                                  <Col span={6}>
                                    {item.date}
                                  </Col>
                                  <Col span={2} className="center">
                                    {item.time}
                                  </Col>
                                </Row>
                              </a>
                            </div>
                      </div>
                    </List.Item>
                  )}
                />
              ) : (
                "Không tìm thấy thông tin"
              )}
            </Skeleton>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ServiceResultModal;
