import moment from 'moment';
// import 'intl/locale-data/jsonp/en';
// import moment from 'moment';
// import 'moment/locale/vi';
// import {UserTypes} from 'types';
// moment.locale('vi');

const formatMoney = (money: number) => {
  let result = money.toString();
  let i = money.toString().length - 3;
  while (i > 0) {
    result = result.slice(0, i) + '.' + result.slice(i);
    i -= 3;
  }
  return result;
};

const dayNames: string[] = ['Hai', 'Ba', 'Tư', 'Năm', 'Sáu', 'Bảy', 'CN'];

const formatDate = (date: Date) => {
  let temp = moment(date).format('D [tháng] M, Y');
  const number: number = parseInt(moment(date).format('E').toString(), 10);
  if (number === 7) {
    return dayNames[number - 1] + ', ' + temp;
  }
  return 'T.' + dayNames[number - 1] + ', ' + temp;
};

const formatDay = (date: string) => {
  const dateFormat = "DD-MM-YYYY";
  return moment(date).format(dateFormat);
}

const calculateAge = (dateString: string) => {
  let today = new Date();
  let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const getValueByKey = (data: any, keyToFind: any) => {
  const item = data.find((item: any) => item.key === keyToFind);
  return item ? item.value : '';
}

const disabledDatePrevious = (current: any) => {
  return current && current < moment().startOf('day'); // Vô hiệu hóa tất cả các ngày nhỏ hơn hôm nay
};

const formatDataPrescriptionAppend = (medicines : any, prescriptions: any) => {
  let resultPrescription = [];
  prescriptions.forEach((item, index) => {
    resultPrescription[index] = {
      medicine: getValueByKey(item, 'medicine'),
      use: getValueByKey(item, 'use'),
      quantity: getValueByKey(item, 'quantity'),
      days: getValueByKey(item, 'days'),
      morning: getValueByKey(item, 'morning'),
      noon: getValueByKey(item, 'noon'),
      afternoon: getValueByKey(item, 'afternoon'),
      evening: getValueByKey(item, 'evening'),
      price: getValueByKey(item, 'price'),
      medicineName: getValueByKey(item, 'medicineName'),
    };
  });

  medicines.forEach((medicine) => {
    resultPrescription.forEach((prescription) => {
      if (parseInt(medicine?.id) === parseInt(prescription?.medicine)) {
        prescription["cost_of_goods_sold"] = medicine?.cost_of_goods_sold * Number(prescription["quantity"]);
      }
    });
  });
  
  let sumCostPrice = resultPrescription.reduce((accumulator, currentValue) => {
    return accumulator + currentValue?.cost_of_goods_sold;
  }, 0);

  return {
    'resultPrescription': resultPrescription,
    'sumCostPrice': sumCostPrice
  };
}

export default {
  formatMoney,
  formatDate,
  calculateAge,
  formatDay,
  getValueByKey,
  disabledDatePrevious,
  formatDataPrescriptionAppend
};
