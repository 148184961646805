import {
  Button,
  DatePicker,
  List,
  Avatar,
  Space,
  ConfigProvider,
  Spin,
  message,
  Row,
  Col,
} from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { images } from "src/assets/";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import viVN from "antd/lib/locale/vi_VN";
import "moment/locale/vi";
import { useSelector } from "react-redux";
import { UserProperty } from "src/types";
import _ from "lodash";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";
const dateFormat1 = "DD-MM-YYYY";

export default function Statistical() {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  const maxEndDate = moment(date.toISOString().slice(0, 10));
  const isDisabledEndDate = (date) => {
    return date.isAfter(maxEndDate, "day");
  };

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const user = useSelector<Storage, UserProperty>((state) => state.user);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const startUTC = moment(startDate, dateFormat1).format(dateFormat);
    const endUTC = moment(endDate, dateFormat1).format(dateFormat);
    try {
      setLoading(true);
      const { data: rData }: any = await api.getStatistical(
        user.id,
        startUTC,
        endUTC
      );
      if (rData) {
        const newData = _.map(rData, (item) => ({
          img: item.name,
          name: item.name,
          value: parseInt(item.value),
          key: item.id,
        }));
        setData(newData);
        setLoading(false);
      } else {
        message.error("Lỗi");
      }
    } catch (error) {
      message.error(error);
    }
  };

  const handleSubmit = async () => {
    const startUTC = moment(startDate, dateFormat1).format(dateFormat);
    const endUTC = moment(endDate, dateFormat1).format(dateFormat);

    setLoading(true);
    const { data: rData }: any = await api.getStatistical(
      user.id,
      startUTC,
      endUTC
    );
    if (rData) {
      const newData = _.map(rData, (item, key) => ({
        img: item.name,
        name: item.name,
        value: parseInt(item.value),
        key: item.id,
      }));

      setData(newData);
      setLoading(false);
    }
  };

  const handleDateChange = (dates: any, dateStrings: any) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
    if (!dateStrings[0]) {
      message.error("Xin hãy chọn ngày tháng năm");
      setLoadingButton(true);
    } else {
      setLoadingButton(false);
    }
    //
  };

  const moneyType = [
    "drug_total",
    "drug_profit",
    "examination_total",
    "order_statistic_tests_total",
  ];

  const defaultRow = [
    {
      img: images.statistical.benhnhandatkham,
      name: "Số lượng bệnh nhân đặt khám",
      value: 0,
      display: true,
      unit: 'bệnh nhân',
    },
    {
      img: images.statistical.benhnhandakham,
      name: "Số lượng bệnh nhân đã khám",
      value: 0,
      display: true,
      unit: 'bệnh nhân',
    },
    {
      img: images.statistical.benhnhanduocgioithieu,
      name: "Số lượng bệnh nhân được giới thiệu",
      value: 0,
      display: true,
      unit: 'bệnh nhân',
    },
    {
      img: images.statistical.doanhsoxetnghiem,
      name: "Số lượng bệnh nhân được giới thiệu đặt khám",
      value: 0,
      display: false,
      unit: 'bệnh nhân',
    },
    {
      img: images.statistical.doanhsoxetnghiem,
      name: "Số lượng bệnh nhân được giới thiệu đã khám",
      value: 0,
      display: false,
      unit: 'bệnh nhân',
    },
    {
      img: images.statistical.benhnhangioithieudi,
      name: "Số lượng bệnh nhân giới thiệu đi",
      value: 0,
      display: true,
      unit: 'bệnh nhân',
    },
    {
      img: images.statistical.benhnhanhuykham,
      name: "Số lượng bệnh nhân hủy khám",
      value: 0,
      display: true,
      unit: 'bệnh nhân',
    },
    {
      img: images.statistical.loinhuanthuoc,
      name: "Lợi nhuận thuốc",
      value: 0,
      display: true,
      unit: 'VNĐ',
    },
    {
      img: images.statistical.doanhsothuoc,
      name: "Doanh số thuốc",
      value: 0,
      display: true,
      unit: 'VNĐ',
    },
    {
      img: images.statistical.doanhsokham,
      name: "Doanh số khám",
      value: 0,
      display: true,
      unit: 'VNĐ',
    },
    {
      img: images.statistical.doanhsoxetnghiem,
      name: "Doanh số xét nghiệm",
      value: 0,
      display: true,
      unit: 'VNĐ',
    },
    {
      img: images.statistical.doanhsoxetnghiem,
      name: "Tổng doanh thu",
      value: 0,
      display: false,
      unit: 'VNĐ',
    },
  ];

  const [dataExport, dataShow] = [
    defaultRow.map((item) => {
      const dataItem = Object.values(data).find((d) => d.name === item.name);
      return {
        ...item,
        value: dataItem
          ? moneyType.includes(dataItem.key || "")
            ? parseFloat(dataItem.value).toLocaleString("vi-VN") + " đ"
            : parseInt(dataItem.value)
          : item.value,
      };
    }),
    defaultRow.filter((item) => item.display).map((item) => {
      const dataItem = Object.values(data).find((d) => d.name === item.name);
      return {
        ...item,
        value: dataItem
          ? moneyType.includes(dataItem.key || "")
            ? parseFloat(dataItem.value).toLocaleString("vi-VN") + " đ"
            : parseInt(dataItem.value)
          : item.value,
      };
    }),
  ];  

  const handleExportToExcel = async (typeFile) => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Thống kê');

      // Tiêu đề các cột
      const headers = ['Phân loại', 'Giá trị', 'Đơn vị'];
  
      // Thêm dòng tiêu đề
      const headerRow = worksheet.addRow(headers);
  
      // Định dạng dòng tiêu đề
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 14 };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '2db7f5' },
        };
      });
      headerRow.height = 30;
  
      // Thêm dữ liệu thống kê
      dataExport.forEach((statistic) => {
        const dataRow = [
          statistic.name,
          statistic.value,
          statistic.unit,
        ];
        worksheet.addRow(dataRow);
      });      
  
      // Định dạng tất cả các dòng dữ liệu
      worksheet.eachRow((rowItem, rowIndex) => {
        if (rowIndex === 1) return; // Bỏ qua dòng tiêu đề
        rowItem.eachCell((cell) => {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });
  
      // Tự động điều chỉnh kích thước cột
      worksheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          if (cell.value) {
            const cellLength = cell.value.toString().length;
            if (cellLength > maxLength) {
              maxLength = cellLength;
            }
          }
        });
        column.width = maxLength + 2; // Cộng thêm khoảng trống
      });
  
      // Lưu file
      const buffer = await workbook.xlsx.writeBuffer();
      const fileName = `Thong_ke_tu_ngay_${startDate}_den_ngay_${endDate}.${typeFile}`;
      saveAs(new Blob([buffer]), fileName);
      message.success('Xuất thống kê thành công.');
    } catch (error) {
      message.error('Lỗi khi xuất Excel:', error);
    }
  };

  moment.locale("vi");

  return (
    <div className="statistical">
      <Spin spinning={loading}>
        <h1>Thống kê</h1>
        <div className="list-statistical">
          <div className="rangerpicker">
            <Row gutter={[16, 16]}>
              <Col>
            <Space direction="vertical" size={12}>
              <ConfigProvider locale={viVN}>
                <RangePicker
                  defaultValue={[
                    moment(startDate, dateFormat1),
                    moment(endDate, dateFormat1),
                  ]}
                  format={dateFormat1}
                  onChange={handleDateChange}
                  disabledDate={isDisabledEndDate}
                />
              </ConfigProvider>
            </Space>
              </Col>
              <Col>
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={loadingButton}
            >
              Lọc
            </Button>
              </Col>
              <Col>
                <Button onClick={() => handleExportToExcel('xlsx')} type="primary" disabled={loadingButton}>
                  Excel
                </Button>
              </Col>
              <Col>
                <Button onClick={() => handleExportToExcel('csv')} type="primary" disabled={loadingButton}>
                  CSV
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <List
              grid={{
                gutter: 0,
                column: 2,
              }}
              dataSource={dataShow}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    style={{
                      // width: 300,
                      height: 60,
                      borderRadius: 10,
                      margin: "0 20px",
                      background: "#effaff",
                      boxShadow: "0px 4px 4px 0px #67daff33",
                    }}
                    avatar={<Avatar src={item.img} alt="{item.name}" />}
                    title={item.name}
                    description={item.value.toLocaleString("vi-VN")}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
