import { BreadcrumbComponent } from "src/components";
import Statistical from "../Statistical";
import NewsList from "src/components/News/NewsList";

const breadcrumbItems = [{ path: "/", name: "Trang chủ" }];

export default function Dashboard() {
  return (
    <div>
      <BreadcrumbComponent items={breadcrumbItems} />
      <Statistical />
      <NewsList />
    </div>
  );
}
