import { Button, Col, ConfigProvider, DatePicker, Form, Input, Modal, Row, Select, Space, Table, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CheckCircleOutlined, ExclamationCircleOutlined, StopOutlined } from "@ant-design/icons";
import { api } from "src/services";
import { contains, utils } from "src/utils";
import _ from "lodash";
import {
  AvatarName,
  BreadcrumbComponent,
  ModalDelete,
  SpanMailTo,
  SpanPhone,
} from "src/components";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import { useDispatch } from "react-redux";
import { allActions } from "src/redux";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import viVN from "antd/lib/locale/vi_VN";
import moment from "moment";
import "moment/locale/vi";
const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default function MyPatient() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [listPatients, setListPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [provinceLoading, setProvinceLoading] = useState(false);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [wardLoading, setWardLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [isOpenModalPatientInfoRegister, setOpenModalPatientInfoRegister] = useState(false);
  const [formDelete] = Form.useForm();
  const [formPatientInfoRegister] = Form.useForm();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  const maxEndDate = moment(date.toISOString().slice(0, 10));
  const isDisabledEndDate = (date) => {
    return date.isAfter(maxEndDate, "day");
  };
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [loadingButton, setLoadingButton] = useState(false);
  const dateFormat1 = "DD-MM-YYYY";
  const dateFormat = "YYYY-MM-DD";
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    fetchPatient();
  }, [searchValue]);

  const handleViewDetailPatient = (id) => {
    history.push(`${path}/schedule-detail/${id}`);
  };

  const handleChat = async (user: any) => {
    dispatch(allActions.option.saveOption(user));
  }

  const handleExportToExcel = async (typeFile) => {
    setLoadingButton(true);
    try {
      const startUTC = moment(startDate, dateFormat1).format(dateFormat);
      const endUTC = moment(endDate, dateFormat1).format(dateFormat);

      const { data: dataExport }: any = await api.getInfoPatients(contains.patientType, startUTC, endUTC);

      if (!dataExport) {
        message.error("Không có dữ liệu để xuất");
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Danh sách bệnh nhân');

      // Tiêu đề các cột
      const headers = [
        'ID',
        'Tên',
        'Email',
        'Số điện thoại',
        'Giới tính',
        'Địa chỉ',
        'Ngày sinh',
      ];

      // Thêm dòng tiêu đề chính
      const headerRow = worksheet.addRow(headers);

      // Định dạng dòng tiêu đề chính
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 14 };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '2db7f5' },
        };
      });
      headerRow.height = 30;

      // Cố định 2 dòng đầu và 2 cột đầu
      worksheet.views = [
        {
          xSplit: 2, // Cố định 2 cột đầu
          ySplit: 2, // Cố định 2 dòng đầu
          topLeftCell: 'C3', // Di chuyển điểm bắt đầu (là vị trí bắt đầu sau dòng và cột cố định)
          activeCell: 'C3', // Chọn vị trí ban đầu
          state: 'frozen', // Đảm bảo trạng thái cố định
        }
      ];

      // Hợp nhất dòng 1 và dòng 2 A:G
      ['A1:A2', 'B1:B2', 'C1:C2', 'D1:D2', 'E1:E2', 'F1:F2', 'G1:G2'].forEach((range) => {
        worksheet.mergeCells(range);
      });

      // H1
      worksheet.mergeCells('I1:Q1');
      // R1
      worksheet.mergeCells('S1:U1');
      // V1
      worksheet.mergeCells('W1:AL1');
      // AM1
      worksheet.mergeCells('AN1:AO1');
      // AP1
      worksheet.mergeCells('AQ1:AY1');
      const rowSheet = worksheet.getRow(1);
      rowSheet.getCell(9).value = 'Khám Bệnh';
      rowSheet.getCell(20).value = 'Thuốc';
      rowSheet.getCell(24).value = 'Chỉ Định Xét Nghiệm';
      rowSheet.getCell(41).value = 'Giới thiệu';
      rowSheet.getCell(44).value = 'Bộ xét nghiệm';
      rowSheet.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 14 };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '2db7f5' },
        };
      });

      const subHeaders = {
        // H2
        I2: 'Thời gian đặt khám',
        J2: 'Thời gian bắt đầu khám',
        K2: 'Thời gian kết thúc khám',
        L2: 'Ngày khám',
        M2: 'Phương thức thanh toán',
        N2: 'Trạng thái thanh toán',
        O2: 'Bác sĩ chỉ định',
        P2: 'Dịch vụ',
        Q2: 'Giá',
        // R2
        S2: 'Loại thuốc',
        T2: 'Tổng giá',
        U2: 'Lợi nhuận',
        // V2
        W2: 'Gói xét nghiệm',
        X2: 'Xét nghiệm tại',
        Y2: 'Loại xét nghiệm',
        Z2: 'Tổng giá xét nghiệm',
        AA2: 'Phương thức thanh toán',
        AB2: 'Trạng thái thanh toán',
        AC2: 'Tình trạng',
        AD2: 'Nhân viên lấy mẫu (Collector)',
        AE2: 'Thời gian lấy mẫu',
        AF2: 'Trung tâm xét nghiệm (Hub)',
        AG2: 'Thời gian trung tâm nhân mẫu',
        AH2: 'Nhân viên vận chuyển (Logistic)',
        AI2: 'Thời gian nhân viên vận chuyển nhận mẫu',
        AJ2: 'Nhân viên xét nghiệm (Lab)',
        AK2: 'Thời gian bắt đầu xét nghiệm',
        AL2: 'Thời gian kết thúc xét nghiệm',
        // AM2
        AN2: 'Chuyển tới bác sĩ khác để khám',
        AO2: 'Nhận từ bác sĩ khác để khám',
        // AP2
        AQ2: 'Thời gian lấy mẫu',
        AR2: 'Ngày lấy mẫu',
        AS2: 'Địa chỉ lấy mẫu',
        AT2: 'Nhân viên lấy mẫu',
        AU2: 'Gói xét nghiệm',
        AV2: 'Tổng giá',
        AW2: 'Phương thức thanh toán',
        AX2: 'Trạng thái thanh toán',
        AY2: 'Tình trạng',
      };

      Object.entries(subHeaders).forEach(([cellAddress, value]) => {
        const cell = worksheet.getCell(cellAddress);
        cell.value = value;
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 12 };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '2db7f5' },
        };
      });

      // Thêm dữ liệu bệnh nhân
      dataExport.forEach((patient) => {
        // Dữ liệu từ A đến G
        const patientData = [
          patient.id ?? '', // Column A
          patient.name ?? '', // Column B
          patient.email ?? '', // Column C
          patient.phone ?? '', // Column D
          patient.gender === 'male' ? 'Nam' : patient.gender === 'female' ? 'Nữ' : '', // Column E
          patient.address ?? '', // Column F
          patient.dob ?? '', // Column G
        ];

        // Hàm để định dạng dữ liệu booking
        const formatBookingData = (booking) => [
          '', // Column H (trống)
          moment(booking.created_at).format("HH:mm DD-MM-YYYY") ?? '', // Column I
          booking.slot_start_time ?? '', // Column J
          booking.slot_end_time ?? '', // Column K
          moment(booking.slot_date).format("DD-MM-YYYY") ?? '', // Column L
          booking.payment_method?.label ?? '', // Column M
          booking.payment_confirm?.label ?? '', // Column N
          booking.service?.member?.name ?? '', // Column O
          booking.service?.name ?? '', // Column P
          booking.service?.price ?? '', // Column Q
        ];

        // Hàm để định dạng dữ liệu prescription
        const formatPrescriptionData = (item) => [
          '', // Column R (trống)
          item?.prescriptions?.map(prescription => `${prescription?.medicine} số lượng ${prescription?.quantity} giá ${prescription?.price}`).join(',\n') ?? '', // Column S
          item?.total ?? '', // Column T
          item?.profit ?? '', // Column U 
        ];

        // Hàm để định dạng dữ liệu test
        const formatTestData = (test) => [
          '', // Column V  (trống)
          test.packet ? `${test.packet?.name} - ${test.packet?.price}` : '', // Column W 
          test.sampling_packages ? `${test.sampling_packages?.name} - ${test.sampling_packages?.price}` : '', // Column X 
          test.type_test?.map(item => {
            return `${item.name} - ${item.price}`;
          }).join(',\n'), // Column Y 
          test.total ?? '', // Column Z 
          test.payment?.label ?? '', // Column AA 
          test.payment_confirm?.label ?? '', // Column AB 
          test.collect_schedules?.map(collect_schedule => {
            const status = collect_schedule?.status?.value;
            const memberName = collect_schedule?.member?.name ?? 'N/A';
            const requestAt = collect_schedule?.request_at ?? 'N/A';
            
            let statusMessage = '';
            switch (status) {
              case 'pending':
                statusMessage = `Đang chờ phản hồi từ ${memberName}`;
                break;
              case 'accept':
                statusMessage = `${memberName} đã chấp nhận yêu cầu lấy mẫu`;
                break;
              case 'deny':
                statusMessage = `${memberName} đã từ chối yêu cầu lấy mẫu`;
                break;
              case 'cancel':
                statusMessage = `Yêu cầu lấy mẫu của ${memberName} đã bị huỷ bởi hệ thống`;
                break;
              case 'deny_after_accept':
                statusMessage = `${memberName} đã huỷ lấy mẫu sau khi đồng ý`;
                break;
              case 'collected':
                statusMessage = `${memberName} đã hoàn thành lấy mẫu`;
                break;
              default:
                statusMessage = 'Trạng thái không xác định';
            }
      
            return `${requestAt} - ${statusMessage}`;
          }).join(',\n'), // Column AC 
          test.collector?.name ?? '', // Column AD 
          test.collected_at ?? '', // Column AE 
          test.hub?.name ?? '', // Column AF 
          test.transfer_hub_at ?? '', // Column AG 
          test.logistic?.name ?? '', // Column AH 
          test.transfer_logistic_at ?? '', // Column AI 
          test.lab?.name ?? '', // Column AJ 
          test.lab_start_at ?? '', // Column AK 
          test.lab_complete_at ?? '', // Column AL
        ];
      
        // Hàm để định dạng dữ liệu referred
        const formatReferredData = (referred) => [
          `Nhận từ BS "${referred.referrer_name}" với lời nhắn "${referred.note}"`, // Column AO
        ];

        // Hàm để định dạng dữ liệu combo test booking
        const formatComboTestBookingData = (comboTest) => [
          '', // Column AP (trống)
          comboTest.time ?? '', // Column AQ
          moment(comboTest.date).format("DD-MM-YYYY") ?? '', // Column AR
          comboTest.address ?? '', // Column AS
          comboTest.collector?.name ?? '', // Column AT
          comboTest.combo_items ? JSON.parse(comboTest.combo_items).map(comboItem => `${comboItem.name} - ${comboItem.price}`).join(',\n') : '', // Column AU
          comboTest.total_price ?? '', // Column AV
          comboTest.payment_method?.label ?? '', // Column AW
          comboTest.payment_confirm?.label ?? '', // Column AX
          comboTest.status?.label ?? '', // Column AY
        ];

        // Dữ liệu từ H đến Q
        const bookingData = patient.bookings?.map(formatBookingData);

        // Dữ liệu từ R đến U
        const prescriptionData = patient.prescriptions?.map(formatPrescriptionData);

        // Dữ liệu từ V đến AL
        const testData = patient.order_patient_tests?.map(formatTestData);

        // Dữ liệu từ AN đến AO
        const referredData = patient.referred_doctors?.map(formatReferredData);

        // Dữ liệu từ AP đến AY
        const comboTestBookingData = patient.combo_test_bookings?.map(formatComboTestBookingData);

        // Kết hợp dữ liệu
        const maxRows = Math.max(bookingData.length, prescriptionData.length, testData.length, referredData.length);

        const combinedData = Array.from({ length: maxRows }, (_, index) => [
          ...(bookingData[index] || Array(10).fill('')), // Cột H đến Q
          ...(prescriptionData[index] || Array(4).fill('')), // Cột R đến U
          ...(testData[index] || Array(17).fill('')), // Cột V đến AL
          ...(patient.referral_doctor ? [
            '', // Column AM (trống)
            `Chuyển tới BS "${patient.referral_doctor?.referrer_name}" với lời nhắn "${patient.referral_doctor?.note}"`
          ] : Array(2).fill('')), // Column AN
          ...(referredData[index] || Array(1).fill('')), // Column AO
          ...(comboTestBookingData[index] || Array(10).fill('')), // Cột AP đến AY
        ]);

        const startRow = worksheet.lastRow ? worksheet.lastRow.number + 1 : 1;

        if (combinedData.length > 0) {
          combinedData.forEach((item) => {
            worksheet.addRow([
              ...patientData.map(value => value ?? ''),
              ...item,
            ]);
          });

          const endRow = worksheet.lastRow.number;
          ['A', 'B', 'C', 'D', 'E', 'F', 'G'].forEach((col) => {
            worksheet.mergeCells(`${col}${startRow}:${col}${endRow}`);
          });
        } else {
          worksheet.addRow([
            ...patientData.map(value => value ?? ''),
            '', // Column H (trống)
            ...Array(9).fill(''), // Columns I to Q
            '', // Column R (trống)
            ...Array(3).fill(''), // Columns S to U
            '', // Column V (trống)
            ...Array(16).fill(''), // Columns W to AL
            '', // Column AM (trống)
            ...Array(2).fill(''), // Columns AN to AO
            '', // Column AP (trống)
            ...Array(9).fill(''), // Columns AQ to AY
          ]);
        }
      });

      // Định dạng tất cả các dòng dữ liệu
      worksheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) return; // Bỏ qua dòng tiêu đề
        row.eachCell((cell) => {
          cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });

      // Tự động điều chỉnh kích thước cột
      worksheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          if (cell.value) {
            const cellLength = cell.value.toString().length;
            if (cellLength > maxLength) {
              maxLength = cellLength;
            }
          }
        });

        alignColumnText(worksheet, ['A', 'D', 'E', 'G', 'I', 'J', 'K', 'L', 'M', 'N', 'AC', 'AE', 'AG', 'AI', 'AJ', 'AQ', 'AR', 'AW', 'AX', 'AY'], {
          vertical: 'middle', // Căn giữa theo chiều dọc
          horizontal: 'center', // Căn giữa theo chiều ngang
          wrapText: true, // Tự xuống dòng nếu cần
        });      

        clearColumnFormatting(worksheet, ['H', 'R', 'V', 'AM', 'AP']);

        // Cập nhật chiều rộng cột nhưng không vượt quá MAX_COLUMN_WIDTH
        column.width = Math.min(maxLength + 2, 50);
      });

      // Lưu file
      const buffer = await workbook.xlsx.writeBuffer();
      const fileName = `Danh_sach_benh_nhan_tu_ngay_${startDate}_den_ngay_${endDate}.${typeFile}`;
      saveAs(new Blob([buffer]), fileName);
      message.success('Xuất danh sách bệnh nhân thành công.');
    } catch (error) {
      message.error('Lỗi khi xuất Excel:', error);
    } finally {
      setLoadingButton(false);
    }
  };

  const alignColumnText = (worksheet, columnLetters, alignmentOptions) => {
    // Căn giữa cho hai dòng đầu tiên của toàn bộ worksheet
    [1, 2].forEach((rowIndex) => {
      const row = worksheet.getRow(rowIndex);
      row.eachCell((cell) => {
        cell.alignment = {
          vertical: 'middle', // Căn giữa theo chiều dọc
          horizontal: 'center', // Căn giữa theo chiều ngang
          wrapText: true, // Tự xuống dòng nếu cần
        };
      });
    });
  
    // Căn chỉnh cho các cột được chỉ định
    columnLetters.forEach((col) => {
      worksheet.getColumn(col).eachCell((cell, rowIndex) => {
        if (rowIndex > 2) { // Bỏ qua hai dòng đầu tiên
          cell.alignment = {
            ...alignmentOptions, // Các tùy chọn căn chỉnh
          };
        }
      });
    });
  };

  // Xóa định dạng của các cột
  const clearColumnFormatting = (worksheet, columnLetters) => {
    columnLetters.forEach((col) => {
      worksheet.getColumn(col).eachCell((cell) => {
        // Xóa định dạng
        cell.font = {};
        cell.alignment = {};
        cell.border = {};
        cell.fill = {};

        // Xóa giá trị
        cell.value = null;
      });
    });
  };

  const handleDateChange = (dates: any, dateStrings: any) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
    if (!dateStrings[0]) {
      message.error("Xin hãy chọn ngày tháng năm");
      setLoadingButton(true);
    } else {
      setLoadingButton(false);
    }
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 20,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      render: (text: string, record: any) => (
        <>
          <Row gutter={8}>
            <Col span={7}>
              <Tag
                color="#87d068"
                style={{ cursor: 'pointer', width: '100%', textAlign: 'center' }}
                onClick={() => handleViewDetailPatient(record?.id)}
                className="text-ellipsis"
              >
                Khám ngay
              </Tag>
            </Col>
            <Col span={7}>
              <Tag
                color="#108ee9"
                style={{ cursor: 'pointer', width: '100%', textAlign: 'center' }}
                onClick={() => handleClickTest(record.id)}
                className="text-ellipsis"
              >
                Xét Nghiệm
              </Tag>
            </Col>
            <Col span={6}>
              <Tag
                color="blue"
                style={{ cursor: 'pointer', width: '100%', textAlign: 'center' }}
                onClick={() => handleChat(record)}
                className="text-ellipsis"
              >
                Liên hệ
              </Tag>
            </Col>
            <Col span={4}>
              <Tag
                color="#f50"
                style={{ cursor: 'pointer', width: '100%', textAlign: 'center' }}
                onClick={() => {
                  setOpenModalDelete(true);
                  formDelete.setFieldsValue({
                    id: record.id,
                    status: record.status,
                  });
                }}
              >
                Xoá
              </Tag>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Tên",
      dataIndex: "avatar",
      key: "avatar",
      render: (avatar, record) => (
        <div>
          <AvatarName
            avatar={avatar}
            name={record?.name}
            profileDoctor={() => {
              history.push(`/my-patient/schedule-detail/${record.id}`);
            }}
            viewDetail={() => handleViewDetailPatient(record?.id)}
          />
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: string, record: any) => <SpanMailTo mail={text} />,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      render: (text: string, record: any) => <SpanPhone phone={text} />,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      render: (text: string, record: any) => (
        <span className="">{text}</span>
      ),
    },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      width: 100,
      render: (text: string, record: any) => (
        <span>{text === "male" ? "Nam" : text === "female" ? "Nữ" : ""}</span>
      ),
    },
    {
      title: "Tuổi",
      dataIndex: "dob",
      key: "dob",
      width: 100,
      render: (text: string, record: any) => utils.calculateAge(text),
    },
    {
      title: "Cài app",
      dataIndex: "is_register",
      key: "is_register",
      width: 100,
      render: (text: number, record: any) => (
        <div style={{ textAlign: 'center' }}>
          {
            text === 1
              ? <CheckCircleOutlined style={{ cursor: 'default', color: 'green' }} />
              : <StopOutlined style={{ cursor: 'default' }} />
          }
        </div>
      ),
    },
  ];
  const handleClickTest = (id) => {
    history.push(`${path}/order-patient-test/${id}`);
  };

  const fetchPatient = async (page = 1, pageSize = 10) => {
    try {
      handleProvinces();
      setLoading(true);
      const { data: rPatitent, meta }: any = await api.getMyPatient(contains.patientType, page, searchValue, pageSize);
      setLoading(false);
      if (rPatitent) {
        let patientArr = rPatitent.map((el: any) => ({ key: el?.id, ...el }));
        setTableParams({
          ...tableParams,
          pagination: {
            current: page,
            pageSize: meta.per_page,
            total: meta.total,
          },
        });
        setListPatients(patientArr);
      }
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchPatient(pagination.current, pagination.pageSize);
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRowKeys);
      setSelectedRows(selectedRows.map((el: any) => el?.id));
    },
    onSelect: (record: any, selected: any, selectedRows: any) => {
      setSelectedRows(selectedRows.map((el: any) => el?.id));
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      setSelectedRows(selectedRows.map((el: any) => el?.id));
    },
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteMyPatient(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchPatient();
      setLoading(false);
      handleCanel();
    }
  };

  const handleCanel = () => {
    setOpenModalDelete(false);
  };

  const onConfirmDelete = () => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa không?",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const response: any = await api.deleteAllMyPatient(selectedRows);
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          console.log(error);
          message.error(error);
        } finally {
          setSelectedRows([]);
          fetchPatient();
        }
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };

  const breadcrumbItems = [
    { path: "/", name: "Trang chủ" },
    { path: "/my-patient", name: "Bệnh nhân của tôi" },
  ];

  const handleCancel = () => {
    setOpenModalPatientInfoRegister(false);
  };

  const handlePatientInfoRegister = async (values) => {
    const formData = {
      name: values.name,
      email: values.email,
      dob: values.dob,
      gender: values.gender,
      phone: values.phone,
      province_id: values.province_id,
      district_id: values.district_id,
      ward_id: values.ward_id,
      short_address: values.short_address,
    };

    try {
      setLoading(true);
      const response: any = await api.patientInfoRegister(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      fetchPatient();
      setLoading(false);
      handleCancel();
    }
  };

  const handleProvinces = async () => {
    try {
      setProvinceLoading(true);
      setDistricts([]);
      setWards([]);
      const response: any = await api.getAllProvinces();
      if (response.error) {
        message.error(response.message);
      } else {
        setProvinces(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProvinceLoading(false);
    }
  };

  const handleProvinceChange = async (provinceId: number) => {
    try {
      setDistrictLoading(true);
      if (formPatientInfoRegister) {
        formPatientInfoRegister.resetFields(['district_id', 'ward_id']);
      }
      setDistricts([]);
      setWards([]);
      setWardLoading(true);
      setSelectedProvince(provinceId);

      const response: any = await api.getDistrictsByProvince(provinceId);
      if (response.error) {
        message.error(response.message);
      } else {
        setDistricts(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDistrictLoading(false);
      setWardLoading(false);
    }
  };

  const handleDistrictChange = async (districtId: number) => {
    try {
      setWardLoading(true);
      if (formPatientInfoRegister) {
        formPatientInfoRegister.resetFields(['ward_id']);
      }
      setWards([]);
      setSelectedDistrict(districtId);
      const response: any = await api.getWardsByDistrict(districtId);
      if (response.error) {
        message.error(response.message);
      } else {
        setWards(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setWardLoading(false);
    }
  };
  const addFriend = async () => {
    try {
      const res = await api.addFreind(searchValue);
    } catch (error) {
      console.error(error);
    }
  }

  moment.locale("vi");

  return (
    <div className="admin-table my-patient">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div className="wp-action" style={{ marginBottom: "1rem" }}>
          <div className="wp-action-left">
            <Button
              onClick={onConfirmDelete}
              disabled={selectedRows.length === 0}
              style={{
                marginRight: ".5rem",
              }}
              type="primary"
              danger
            >
              Xoá
            </Button>
            {
              (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
              <Button
                onClick={addFriend}
                type="primary"
                danger
              >
                Thêm bạn
              </Button>
            }
            <Input
              style={{
                width: 250,
                marginRight: ".5rem",
              }}
              allowClear
              onChange={_.debounce(function (e: any) {
                setSearchValue(e.target.value);
              }, 300)}
              placeholder="Tìm kiếm"
            />
          </div>
          <div className="wp-action-right">
            <Row gutter={[16, 16]}>
              <Col>
                <Space direction="vertical" size={12}>
                  <ConfigProvider locale={viVN}>
                    <RangePicker
                      defaultValue={[
                        moment(startDate, dateFormat1),
                        moment(endDate, dateFormat1),
                      ]}
                      format={dateFormat1}
                      onChange={handleDateChange}
                      disabledDate={isDisabledEndDate}
                    />
                  </ConfigProvider>
                </Space>
              </Col>
              <Col>
                <Button
                  onClick={() => handleExportToExcel('xlsx')}
                  type="primary"
                  loading={loadingButton}
                  disabled={loadingButton}
                >
                  Excel
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => handleExportToExcel('csv')}
                  type="primary"
                  loading={loadingButton}
                  disabled={loadingButton}
                >
                  CSV
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setOpenModalPatientInfoRegister(true);
                  }}
                  type="primary"
                >
                  Tạo thông tin bệnh nhân
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <Table
          rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={listPatients}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
        <ModalDelete
          open={openModalDelete}
          cancel={handleCanel}
          form={formDelete}
          handleSubmit={handleDelete}
          loading={loading}
          title={"Xóa"}
        />

        <Modal
          title="Tạo thông tin bệnh nhân"
          open={isOpenModalPatientInfoRegister}
          footer={null}
          onCancel={handleCancel}
          width={960} // Tăng chiều rộng Modal
        >
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            form={formPatientInfoRegister}
            onFinish={handlePatientInfoRegister}
          >
            <Row gutter={16}>
              {/* Cột 1 */}
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Tên"
                  rules={[{ required: true, message: 'Vui lòng nhập tên bệnh nhân' }]}
                >
                  <Input placeholder="Nhập tên bệnh nhân" />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { type: 'email', message: 'Vui lòng nhập email hợp lệ' },
                    { max: 60, message: 'Email không được vượt quá 60 ký tự' },
                    { min: 6, message: 'Email phải có ít nhất 6 ký tự' },
                    { required: false }
                  ]}
                >
                  <Input placeholder="Nhập email" />
                </Form.Item>

                <Form.Item
                  name="dob"
                  label="Ngày sinh"
                  rules={[
                    { required: true, message: 'Vui lòng chọn ngày sinh' },
                    {
                      validator: (_, value) => {
                        if (!value) return Promise.resolve();

                        const today = new Date();
                        const birthDate = new Date(value);
                        let age = today.getFullYear() - birthDate.getFullYear();
                        const monthDiff = today.getMonth() - birthDate.getMonth();
                        const dayDiff = today.getDate() - birthDate.getDate();

                        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                          age--;
                        }

                        if (age < 0) {
                          return Promise.reject(
                            new Error('Vui lòng chọn đúng ngày sinh, không chọn ngày trong tương lai!')
                          );
                        }

                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <DatePicker placeholder="Chọn ngày sinh" style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              {/* Cột 2 */}
              <Col span={12}>
                <Form.Item
                  name="gender"
                  label="Giới tính"
                  rules={[{ required: true, message: 'Vui lòng chọn giới tính' }]}
                >
                  <Select placeholder="Chọn giới tính">
                    <Select.Option value="male">Nam</Select.Option>
                    <Select.Option value="female">Nữ</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="phone"
                  label="Số điện thoại"
                  rules={[
                    { required: true, message: 'Vui lòng nhập số điện thoại' },
                    { pattern: /^0[3|5|7|8|9]\d{8}$/, message: 'Số điện thoại không hợp lệ' }
                  ]}
                >
                  <Input placeholder="Nhập số điện thoại" />
                </Form.Item>

                <Form.Item
                  name="province_id"
                  label="Tỉnh/Thành phố"
                  rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}
                >
                  <Select
                    placeholder="Chọn tỉnh/thành phố"
                    onChange={handleProvinceChange}
                    loading={provinceLoading}
                  >
                    {provinces.map((province) => (
                      <Option key={province.matp} value={province.matp}>
                        {province.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              {/* Cột 1 */}
              <Col span={12}>
                <Form.Item
                  name="district_id"
                  label="Quận/Huyện"
                  rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}
                >
                  <Select
                    placeholder="Chọn quận/huyện"
                    onChange={handleDistrictChange}
                    disabled={!selectedProvince}
                    loading={districtLoading}
                  >
                    {districts.map((district) => (
                      <Option key={district.maqh} value={district.maqh}>
                        {district.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="ward_id"
                  label="Phường/Xã"
                  rules={[{ required: true, message: 'Vui lòng chọn phường/xã' }]}
                >
                  <Select
                    placeholder="Chọn phường/xã"
                    disabled={!selectedDistrict}
                    loading={wardLoading}
                  >
                    {wards.map((ward) => (
                      <Option key={ward.xaid} value={ward.xaid}>
                        {ward.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Cột 2 */}
              <Col span={12}>
                <Form.Item
                  name="short_address"
                  label="Địa chỉ"
                  rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
                >
                  <TextArea placeholder="Nhập địa chỉ" rows={4} />
                </Form.Item>

                <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
                  <Button type="primary" loading={loading} htmlType="submit">
                    Lưu
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  );
}
