import { Button, Card, Col, Form, Input, Row, message } from "antd";
import { api } from "src/services";
import { useState, useRef } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { BreadcrumbComponent } from "src/components";

export default function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<any>(null);

  const handleSubmit = async (values: any) => {
    try {
      let formData = new FormData();
      formData.append("password_current", values.nampassword_currente);
      formData.append("password", values.password);
      formData.append("password_confirmation", values.password_confirmation);
      setLoading(true);
      const res: any = await api.changePassword(values);
      if (!res.error) {
        setLoading(false);
        formRef.current.resetFields();
        message.success("Cập nhật mật khẩu thành công");
      }
    } catch (error: any) {
      setLoading(false);
      if (error?.data?.error) {
        message.error("Cập nhật mật khẩu thất bại!");
      }
    }
  };

  const onFinishFailed = () => {
    message.error("Submit thất bại");
  };
  const breadcrumbItems = [
    { path: "/", name: "Trang chủ" },
    { path: "#", name: "Đổi mật khẩu" },
  ];
  return (
    <div className="admin-table">
      <BreadcrumbComponent items={breadcrumbItems} />
      <Form
        ref={formRef}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        className="form"
      >
        <Row gutter={[16, 16]}>
          <Col span={12} key={1}>
            <Card title="Đổi mật khẩu" bordered={true}>
              <Form.Item
                label="Mật khẩu cũ"
                name="password_current"
                rules={[
                  {
                    required: true,
                    message: "Mật khẩu cũ không được để trống",
                  },
                  {
                    validator(_, value) {
                      if (value.length < 6 && value.length > 0) {
                        return Promise.reject(
                          "Mật khẩu phải chứa ít nhất 6 kí tự"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Mật khẩu mới"
                rules={[
                  {
                    required: true,
                    message: "Mật khẩu mới không được để trống!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value.length < 6 && value.length > 0) {
                        return Promise.reject(
                          "Mật khẩu phải chứa ít nhất 6 kí tự"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <Form.Item
                name="password_confirmation"
                label="Xác nhận mật khẩu mới"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Xác nhận mật khẩu mới không được để trống",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Mật khẩu mới mà bạn đã nhập không khớp!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Card>
          </Col>
          <Col span={12} key={2}>
            <Card
              size="default"
              style={{
                marginBottom: 16,
                height: "100%",
              }}
              title="Xuất bản"
            >
              <Form.Item>
                <Button type="primary" loading={loading} htmlType="submit">
                  Lưu
                </Button>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
