import { Button, Col, DatePicker, Form, Input, Row, Card, message, Upload, Select, Skeleton, Modal} from "antd";
import { useEffect, useState, useRef, useCallback } from "react";
import { api } from "src/services";
import { PlusOutlined, CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { utils } from "src/utils";
import { CardDisease, CardSave, CardCopy, CopySamplePrescription } from "src/components";
import locale from "antd/lib/date-picker/locale/vi_VN";
import moment from "moment";
const dateFormat = "DD/MM/YYYY";
const { Option } = Select;
const PrescriptionAdd = ({ 
  fetchPrescriptionOfPatient,
  patient_name,
  patient_id,
  handleCancel,
  setFileUpload,
  fileUpload,
  number,
  prescriptionCopy
}) => {
  const { TextArea } = Input;
  const formRef = useRef<any>(null);
  const [medicines, setMedicines] = useState([]);
  const [samplePrescriptions, setSamplePresriptions] = useState([]);
  const [diseases, setDisease] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingMedicine, setLoadingMedicine] = useState(false);
  const [loadingDisease, setLoadingDisease] = useState(false);
  const [searchQueryDisease, setSearchQueryDisease] = useState("");
  const [onlyTest, setOnlyTest] = useState<boolean>(false); // true hiểu thị những mục cần xác nhận hình ảnh.
  const [isAddPrescription, setIsAddPrescription] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [openModalCopySamplePrescription, setOpenModalCopySamplePrescription] = useState(false);
  // Chuẩn đoán bệnh
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  // Thuốc
  const minCurrentScroll = 30;
  const [currentScroll, setCurrentScroll] = useState(minCurrentScroll);
  const [total, setTotal] = useState(0);

  // Xử lý thêm thuốc bằng table
  const [totalAll, setTotalPrice] = useState(0);
  const [totalCostAll, setTotalCostPrice] = useState(0);
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    const data = {
      medicineName: "",
      use: "",
      quantity: "",
      days: "",
      morning: "",
      noon: "",
      afternoon: "",
      evening: "",
      price: "",
    };
    initRow([...rows, data]);
  };
  const fetchMedicine = async () => {
    try {
      setLoadingMedicine(true);
      const { data: rData }: any = await api.getAllMedicine();
      if (rData) {
        setMedicines(rData);
      } else {
        setMedicines([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingMedicine(false);
    }
  };

  const fetchSamplePrescription = async (page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const res: any = await api.getSamplePrescription(page, pageSize);
      if (res && res.data) {
        let samplePrescriptionArr = res.data.map((el: any) => ({
          key: el.id,
          ...el,
        }));
        setSamplePresriptions(samplePrescriptionArr);
      } else {
        setSamplePresriptions([]);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMedicine();
    fetchSamplePrescription();
  }, []);

  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    // initRow(data);
  };
  let totalPrice = 0;
  let totalCostPrice = 0;

  // Thêm mới thuốc sẽ tính tổng tiền và tổng giá vốn theo số lượng thuốc
  const tableAddRowPrescription = (index) => {
    const dataRow = [...rows];
    console.log('dataRow', dataRow);
    medicines.forEach((medicine) => {
      if (medicine.id === dataRow[index].medicine) {
        dataRow[index]["price"] =
          medicine.cost_price * Number(dataRow[index]["quantity"]);
        dataRow[index]["costPrice"] =
          medicine.cost_of_goods_sold * Number(dataRow[index]["quantity"]);
      }
    });
    dataRow.forEach((item) => {
      totalPrice += Number(item.price);
      totalCostPrice += Number(item.costPrice);
      setTotalPrice(totalPrice);
      setTotalCostPrice(totalCostPrice);
    });
    initRow(dataRow);
  };

  // Xóa thuốc đã chọn sét lại tổng tiền và tổng vốn
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
    if (dataRow.length === 0) {
      totalPrice = 0;
    }
    dataRow.forEach((item) => {
      totalPrice += item.price;
      totalCostPrice += item.costPrice;
    });
    setTotalPrice(totalPrice);
    setTotalCostPrice(totalCostPrice);
  };
  // Chọn thuốc sẽ apend cách dùng thuốc và lưu lại id thuốc theo tên thuốc đã chọn
  const onChangeMedicine = (i, value, event) => {
    let isExistsMedicine = rows.find((item) => item?.medicine === value);
    if (isExistsMedicine) {
      message.error(`Thuốc ${isExistsMedicine?.medicineName?.value} đã được chọn`);
      return;
    }
    const data = [...rows];
    data[i][`medicineName`] = event?.children;
    medicines.forEach((medicine) => {
      if (medicine.id === value) {
        data[i]["use"] = medicine.drug_route;
        data[i]["medicine"] = medicine?.id;
      }
    });
    initRow(data);
  };

  const handleCopySamplePrescription = () => {
    if (!selectedDiseases.length) {
      message.error("Vui lòng chọn chẩn đoán");
      return;
    }
    if (!rows.length) {
      message.error("Vui lòng chọn chỉ định dùng thuốc");
      return;
    } else {
      let validateMedicine = false;
      rows.forEach((item, index) => {
        if (!item.afternoon || !item.days || !item.evening || !item.medicine || !item.medicineName || !item.morning || !item.noon || !item.price || !item.quantity || !item.use) {
          validateMedicine = true;
          return;
        }
      });
      if (validateMedicine) {
        message.error("Vui lòng nhập đầy đủ thông tin cho thuốc");
        return;
      }
    }

    setOpenModalCopySamplePrescription(true);
  };

  const handleCopySendReq = async (formName) => {
    let rowsCover = rows.map((el: any) => {
      return Object.entries(el)
        .filter(([key, value]) =>
          [
            // Sử dụng filter thay vì map
            "medicine",
            "use",
            "quantity",
            "days",
            "morning",
            "noon",
            "afternoon",
            "evening",
            "price",
          ].includes(key)
        )
        .map(([key, value]) => {
          return { key, value }; // Trả về một giá trị từ mỗi lần map
        });
    });

    rowsCover.forEach((item: any) => {
      let indexMedicine = item.findIndex((ob) => ob.key === "medicine");
      if (indexMedicine !== -1) {
        // Nếu có key "medicine" trong mảng
        const medicineObj = item.splice(indexMedicine, 1)[0]; // Loại bỏ đối tượng "medicine" khỏi mảng con
        item.unshift(medicineObj); // Đưa "medicine" lên đầu mảng
      }
    });

    const dataSubmit = {
      name: formName,
      note: form.getFieldValue("note") ?? '',
      type_disease: selectedDiseases.map((el: any) => el),
      prescriptions: rowsCover,
      total: totalAll || 0,
      profit: totalAll - totalCostAll || 0,
    };
    
    try {
      setLoading(true);
      const response: any = await api.createSamplePrescription(dataSubmit);

      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
        setCurrentScroll(minCurrentScroll);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      setLoading(false);
      fetchPrescriptionOfPatient();
      setCurrentScroll(minCurrentScroll);
      fetchSamplePrescription();
    }
  };

  const handleCancelModal = () => {
    setOpenModalCopySamplePrescription(false);
  };

  function TableRows({ rows, tableRowRemove, onValUpdate }) {
    return rows.map((rowsData, index) => {
      const {
        medicineName,
        use,
        quantity,
        days,
        morning,
        noon,
        afternoon,
        evening,
        price,
      } = rowsData;
      return (
        <tr key={index}>
          <td>
            <input
              type="text"
              value={index + 1}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <Select
              showSearch
              loading={loadingMedicine}
              value={medicineName}
              onSelect={(value, event) => onChangeMedicine(index, value, event)}
            >
              {medicines.map((medicine) => (
                <Option key={medicine.id} value={medicine.id}>
                  {medicine.name}
                </Option>
              ))}
            </Select>
          </td>
          <td>
            <input
              type="text"
              value={use}
              onChange={(event) => onValUpdate(index, event)}
              name="use"
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="quantity"
              defaultValue={quantity || 0}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="days"
              defaultValue={days || 0}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="morning"
              defaultValue={morning || 0}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="noon"
              defaultValue={noon || 0}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="afternoon"
              defaultValue={afternoon || 0}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              min="0"
              name="evening"
              defaultValue={evening || 0}
              onChange={(event) => onValUpdate(index, event)}
              className="form-control"
            />
          </td>
          <td className="input-event-none">
            <input
              type="text"
              value={utils.formatMoney(price)}
              name="price"
              className="form-control"
            />
          </td>
          <td className="addRow">
            <button onClick={() => tableAddRowPrescription(index)}>
              <CheckOutlined />
            </button>
          </td>
          <td>
            <button
              className="btn btn-dark"
              onClick={() => tableRowRemove(index)}
            >
              <DeleteOutlined />
            </button>
          </td>
        </tr>
      );
    });
  }
  useEffect(() => {
    fetchDisease();
    if (number) {
      setLoadingUpload(true);
      setTimeout(() => {
        setLoadingUpload(false);
      }, 1000);
    }
    if (currentScroll) {
      setLoadingDisease(true);
      setTimeout(() => {
        setLoadingDisease(false);
      }, 1000);
    }
  }, [onlyTest, searchQueryDisease, number, currentScroll]);

  const fetchDisease = async () => {
    try {
      setLoadingDisease(true);
      const { data: rData, meta }: any = await api.getTestAllDisease(
        `${currentScroll}`,
        searchQueryDisease
      );
      if (rData) {
        setDisease(rData);
        setTotal(meta.total);
      } else {
        setDisease([]);
        setCurrentScroll(minCurrentScroll);
        setTotal(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDisease(false);
    }
  };

  const handleSelectedDiseases = useCallback((value) => {
    setSelectedDiseases(value);
  }, []);

  const handlePopupScroll = useCallback((e) => {
    const node = e.target;
    const bottom =
      node.scrollHeight - Math.ceil(node.scrollTop) === node.clientHeight;
    if (bottom) {
      setLoadingDisease(true);
      setCurrentScroll((prev) => prev + minCurrentScroll);
    }
  }, []);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const debouncedSearch = debounce(setSearchQueryDisease, 300);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      setFileUpload([]);
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }

    const fileObject = {
      name: file.name,
      size: file.size,
      url: null,
      file: file,
    };
    setFileUpload((prevFiles) => prevFiles.concat(fileObject));

    getBase64(file, (url) => {
      fileObject.url = url;
    });

    return false;
  };

  const handleSubmit = async (filedValues: any) => {
    if (isAddPrescription) {
      if (!selectedDiseases.length) {
        message.error("Vui lòng chọn chẩn đoán");
        return;
      }

      if (!rows.length) {
        message.error("Vui lòng chọn chỉ định dùng thuốc");
        return;
      } else {
        let validateMedicine = false;
        rows.forEach((item) => {
          if (!item.afternoon || !item.days || !item.evening || !item.medicine || !item.medicineName || !item.morning || !item.noon || !item.price || !item.quantity || !item.use) {
            validateMedicine = true;
            return;
          }
        });
        if (validateMedicine) {
          message.error("Vui lòng nhập đầy đủ thông tin cho thuốc");
          return;
        }
      }

      let rowsCover = rows.map((el: any) => {
        return Object.entries(el)
          .filter(([key, value]) =>
            [
              "medicine",
              "use",
              "quantity",
              "days",
              "morning",
              "noon",
              "afternoon",
              "evening",
              "price",
            ].includes(key)
          )
          .map(([key, value]) => {
            return { key, value };
          });
      });
    
      rowsCover.forEach((item: any) => {
        let indexMedicine = item.findIndex((ob) => ob.key === "medicine");
        if (indexMedicine !== -1) {
          // Nếu có key "medicine" trong mảng
          const medicineObj = item.splice(indexMedicine, 1)[0]; // Loại bỏ đối tượng "medicine" khỏi mảng con
          item.unshift(medicineObj); // Đưa "medicine" lên đầu mảng
        }
      });

      const dataSubmit = {
        patient_id: patient_id,
        type_disease: selectedDiseases.map((el: any) => el),
        note: filedValues.note,
        prescriptions: rowsCover,
        medication_indications: "Chỉ đinh dùng thuốc bệnh nhân " + patient_name,
        total: totalAll || 0,
        profit: totalAll - totalCostAll || 0,
        re_examination_date: filedValues.re_examination_date ? filedValues.re_examination_date.format("YYYY-MM-DD") : '',
      };

      try {
        setLoading(true);
        const response: any = await api.createPrescriptionForPatient(
          dataSubmit
        );
        if (response.error) {
          message.error(response.message);
        } else {
          message.success(response.message);
          setCurrentScroll(minCurrentScroll);
        }
      } catch (error) {
        console.log(error);
        message.error(error.message);
      } finally {
        setLoading(false);
        fetchPrescriptionOfPatient();
        form.resetFields();
        formRef.current.resetFields();
        setSelectedDiseases([]);
        handleCancel();
        initRow([]);
        setTotalPrice(0);
        setTotalCostPrice(0);
        setCurrentScroll(minCurrentScroll);
      }
    } else {
      if (!fileUpload.length) {
        return message.error("Vui lòng chọn một hình ảnh");
      }
      let formData: any = new FormData();
      formData.append("patient_id", patient_id);
      fileUpload.forEach((img, index) => {
        formData.append(`images[${index}]`, img.file);
      });
      try {
        setLoading(true);
        const response: any = await api.createPrescriptionForPatient(formData);
        if (response.error) {
          message.error(response.message);
        } else {
          message.success(response.message);
          setFileUpload([]);
          form.resetFields();
          formRef.current.resetFields();
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
        handleCancel();
        fetchPrescriptionOfPatient();
      }
    }
  };

  const onRemove = (file) => {
    const updatedFiles = fileUpload.filter((item) => item.name !== file.name);
    setFileUpload(updatedFiles);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleOnChangeSamplePrescription = (sampleId, option) => {
    if (!sampleId) {
      initRow([]);
      formRef.current.setFieldValue("note", "");
      formRef.current.setFieldValue("re_examination_date", "");
      formRef.current.setFieldValue("type_disease", []);
      setTotalPrice(0);
      return;
    }

    let sampleDetail = samplePrescriptions.find((item) => item.id === sampleId);

    let presriptions = [];
    let sumCostPrice = 0;
    if (sampleDetail && sampleDetail.prescriptions.length > 0) {
      let result = utils.formatDataPrescriptionAppend(medicines, sampleDetail.prescriptions);
      presriptions = result.resultPrescription;
      sumCostPrice = result.sumCostPrice;
    }

    initRow([...presriptions]);
    setTotalPrice(sampleDetail?.total);
    setTotalCostPrice(sumCostPrice);
    setSelectedDiseases([
      ...selectedDiseases,
      ...sampleDetail?.type_disease_ids,
    ]);
    formRef.current.setFieldValue("note", sampleDetail?.note ?? '');
    formRef.current.setFieldValue("type_disease", sampleDetail?.type_disease_ids ?? '');
  };

  // Append khi nhấn nút copy toa thuốc ở modal chi tiết toa thuốc
  useEffect(() => {
    if (prescriptionCopy && medicines && medicines.length){
      let presriptions = [];
      let sumCostPrice = 0;
      if (prescriptionCopy && prescriptionCopy.prescriptions.length > 0) {
        let result = utils.formatDataPrescriptionAppend(medicines, prescriptionCopy.prescriptions);
        presriptions = result.resultPrescription;
        sumCostPrice = result.sumCostPrice;
      }
      initRow([...presriptions]);
      setTotalPrice(prescriptionCopy?.total);
      setTotalCostPrice(sumCostPrice);
      setSelectedDiseases([
        ...selectedDiseases,
        ...prescriptionCopy?.type_disease_ids,
      ]);
      formRef.current.setFieldValue("note", prescriptionCopy?.note ?? '');
      formRef.current.setFieldValue("type_disease", prescriptionCopy?.type_disease_ids ?? '');
    }
  }, [prescriptionCopy, medicines]);

  const handleClickSelectImage = () => {
    setOnlyTest(true);
    setIsAddPrescription(false);
    setFileUpload([]);
    initRow([]);
    formRef.current.setFieldValue("note", "");
    formRef.current.setFieldValue("type_disease", []);
    formRef.current.setFieldValue("re_examination_date", "");
    setTotalPrice(0);
  };

  const handleClickCreatePrescription = () => {
    setIsAddPrescription(true);
    setOnlyTest(false);
    setFileUpload([]);
    setSearchQueryDisease("");
    setCurrentScroll(minCurrentScroll);
  };

  return (
    <div className="prescription-add">
      {isAddPrescription && (
        <div className="select_sample_order_test">
          <h4>Chọn toa thuốc mẫu</h4>
          <Form
            form={form}
            ref={formRef}
            className="form-add-prescription"
            name="form_add_prescription"
          >
            <Form.Item name="name">
              <Select
                placeholder="Chọn toa thuốc mẫu"
                style={{ width: "100%" }}
                onChange={handleOnChangeSamplePrescription}
              >
                <Option value="">Chọn toa thuốc mẫu</Option>
                {samplePrescriptions?.map((item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      )}

      <Form
        onFinish={handleSubmit}
        form={form}
        ref={formRef}
        onFinishFailed={onFinishFailed}
        className="form-add-prescription"
        name="form_add_prescription"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              className="card-date-note"
              size="small"
              style={{
                marginBottom: 16,
              }}
            >
              <Form.Item>
                <Button
                  className={isAddPrescription ? "button-active" : ""}
                  type="default"
                  size="large"
                  onClick={handleClickCreatePrescription}
                >
                  Tạo toa thuốc
                </Button>
                <Button
                  className={onlyTest ? "button-active" : ""}
                  type="default"
                  size="large"
                  style={{ marginLeft: "1rem" }}
                  onClick={handleClickSelectImage}
                >
                  Chọn ảnh
                </Button>
              </Form.Item>
              {isAddPrescription && (
                <>
                  <Form.Item
                    name="re_examination_date"
                    label="Ngày tái khám"
                  >
                    <DatePicker
                      locale={locale}
                      format={dateFormat}
                      placeholder="Chọn ngày tái khám"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ghi chú"
                    name="note"
                  >
                    <TextArea
                      showCount
                      maxLength={500}
                      rows={4}
                      placeholder="Ghi chú"
                    />
                  </Form.Item>
                </>
              )}

              {onlyTest && (
                <Form.Item>
                  <Skeleton loading={loadingUpload} active avatar>
                    <Upload
                      listType="picture-card"
                      showUploadList={true}
                      beforeUpload={onBeforeUpload}
                      multiple={true}
                      onRemove={onRemove}
                      accept=".jpg, .jpeg, .png"
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 4 }}>Upload</div>
                      </div>
                    </Upload>
                  </Skeleton>
                </Form.Item>
              )}
            </Card>
          </Col>
          <Col span={12}>
            {isAddPrescription && (
              <CardDisease
                loading={loading}
                loadingDisease={loadingDisease}
                setSearchQueryDisease={debouncedSearch}
                diseaseCategories={diseases}
                selectedDiseases={handlePopupScroll}
                handleSelectedDiseases={handleSelectedDiseases}
                total={total}
              />
            )}
          </Col>
        </Row>
        {isAddPrescription && (
          <Row>
            <Col span={24}>
              <div>
                <div className="addPrescription" onClick={addRowTable}>
                  Thêm mới thuốc
                </div>
                <table className="table table-striped table_add_prescription">
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên thuốc</th>
                      <th>Cách dùng</th>
                      <th>Số lượng</th>
                      <th>Ngày</th>
                      <th>Sáng</th>
                      <th>Trưa</th>
                      <th>Chiều</th>
                      <th>Tối</th>
                      <th>Đơn giá</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableRows
                      rows={rows}
                      tableRowRemove={tableRowRemove}
                      onValUpdate={onValUpdate}
                    />
                  </tbody>
                </table>
                <div className="d-flex addPrescription__wapper">
                  <div className="addPrescription " onClick={addRowTable}>
                    Thêm mới thuốc
                  </div>
                </div>
                <div className="totalPrice">
                  <h4>Tổng tiền:</h4>
                  <span>{utils.formatMoney(totalAll)} đ</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={3}>
            <CardSave loading={loading} />
          </Col>
          <Col span={3}>
            <CardCopy
              loading={loading}
              onClick={() => handleCopySamplePrescription()}
            />
          </Col>
          <Col span={20}></Col>
        </Row>
      </Form>
      <Modal
        title="Nhập Tên Cho Toa Thuốc Mẫu (Ví dụ: Toa thuốc đau lưng)"
        open={openModalCopySamplePrescription}
        footer={null}
        onCancel={() => setOpenModalCopySamplePrescription(false)}
        width={800}
        className="modal-scroll"
        centered
        destroyOnClose={true}
      >
        <CopySamplePrescription
          handleCopySendReq={handleCopySendReq}
          handleCancelModal={handleCancelModal}
        />
      </Modal>
    </div>
  );
};

export default PrescriptionAdd;
