import {  PlusOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Image, Input, List, message, Modal, Upload } from 'antd'
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { api } from 'src/services';

export default function MessageBox({
    dataSource,
    setData,
    setDataMessage,
    chatId,
    userId,
    cusor,
    loadMoreMessage,
    isScrollDown
}) {
    const messagesEndRef = useRef(null);
    const [content, setContent] = useState("");
    const [fileUpload, setFileUpload] = useState([]);
    const [checkFile, setCheckFile] = useState(null);

    /* FETCH FUNCTION */
    const sendMessage = async(chat_id:any, _content:any)=> {
        try {
            if (!content.trim() && !fileUpload) return;
            let formData = new FormData();
            formData.append("chat_id", chat_id);
            formData.append("content", _content);
            fileUpload.forEach((img, index) => {
                formData.append(`images[${index}]`, img.file);
            });
            setContent("");
            setIsModalVisible(false);
            const { data: _messages }:any = await api.sendMessage(formData);
            setFileUpload([]);
            setDataMessage((prev:any)=> [..._messages,...prev]);
            const res = await api.updateChat(chat_id, { is_read: true});
        } catch (error) {
            console.error(error);
        }
    }

    /* HELPER FUNCTION */
    const isAuthor = ({user}:any) =>{
        return (user._id === userId);
    }

    const getLastTimeSeen = ({createdAt}) =>{
        let timeAgo = moment(createdAt).fromNow();
        return timeAgo;
    }

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSubmit = (event:any) => {
        event.preventDefault();
        if (content.trim()) {
            sendMessage(chatId, content);
        }
    };

    const handleKeyPress = (event:any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSubmit(event);
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [dataSource]);
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const onBeforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          message.error("You can only upload JPG/PNG files!");
          return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error("Image must be smaller than 2MB!");
          return;
        }
        const fileObject = {
          name: file.name,
          size: file.size,
          url: null,
          file: file,
        };
        setFileUpload((prevFiles) => prevFiles.concat(fileObject));
        getBase64(file, (url) => {
          fileObject.url = url;
        });
        return false;
      };
    const onRemove = (file) => {
        const updatedFiles = fileUpload.filter((item) => item.name !== file.name);
        setFileUpload(updatedFiles);
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    // Function to show modal
    const showModal = () => {
        setIsModalVisible(true);
    };
  
    // Function to hide modal
    const handleCancel = () => {
      setIsModalVisible(false);
    };
  
    // Function to handle modal actions (e.g., confirm)
    const handleOk = () => {
        sendMessage(chatId,'');
    };
  
    return (
        <div className='message-box-wrapper'>
            <div className="message-list-wrapper">
                <List
                    dataSource={dataSource}
                    renderItem={(item: any, index :number) => {
                        return (index !== dataSource.length - 1) ?
                            <div className='message-item'>
                                { index === 0 && isScrollDown ? <div ref={messagesEndRef} /> : '' }
                                <List.Item 
                                    style={{ width: "100%" }}
                                    className={isAuthor(item) ? 'author-message' : ''}
                                >
                                    <div className='chat-box'>
                                        <div className="chat-content">
                                            { 
                                                item.text ? 
                                                item.text :
                                                <Image width={200} src={item.image} />
                                            }
                                        </div>
                                        <div className="chat-send-time">
                                            <span style={{fontSize:12}}>{getLastTimeSeen(item)}</span>
                                        </div>
                                    </div>
                                </List.Item>
                            </div> :
                            <>
                                <div className='message-item'>
                                    { index === 0 && isScrollDown ? <div ref={messagesEndRef} /> : '' }
                                    <List.Item 
                                        style={{ width: "100%" }}
                                        className={isAuthor(item) ? 'author-message' : ''}
                                    >
                                        <div className='chat-box'>
                                            <div className="chat-content">
                                                { 
                                                    item.text ? 
                                                    item.text :
                                                    <Image width={200} src={item.image} />
                                                }
                                            </div>
                                            <div className="chat-send-time">
                                                <span style={{fontSize:12}}>{getLastTimeSeen(item)}</span>
                                            </div>
                                        </div>
                                    </List.Item>
                                </div>
                                { cusor ? <div className='message-item'>
                                    { index === 0 ? <div ref={messagesEndRef} /> : '' }
                                    <List.Item 
                                        style={{ width: "100%" }}
                                        className='load-more-message'
                                    >
                                        <Button type="primary" onClick={()=>loadMoreMessage(chatId)}>Thêm</Button>
                                    </List.Item>
                                </div> : <></>}
                               
                            </>

                    }}
                />
            </div>
            <div className="message-input">
                <form onSubmit={handleSubmit}>
                    <textarea 
                        placeholder="Nhập..." 
                        value={content}
                        onChange={(e)=> setContent(e.target.value)} 
                        onKeyPress={handleKeyPress}
                    />
                </form>
                
                <div className="button-send">
                    <Button 
                        type="primary" 
                        shape="circle" 
                        icon={<SendOutlined />} 
                        onClick={()=>sendMessage(chatId, content)}
                    />
                </div>
                <div className="button-upload">
                    <Button 
                        type="primary" 
                        shape="circle" 
                        icon={<PlusOutlined />} 
                        onClick={showModal}
                    />
                </div>
            </div>
            <Modal
                title="Add Item"
                visible={isModalVisible}
                onOk={handleOk}  // Action when user clicks "OK"
                onCancel={handleCancel}  // Action when user clicks "Cancel"
                okText="Confirm"
                cancelText="Cancel"
            >
                    <Upload
                        listType="picture-card"
                        showUploadList={true}
                        fileList={fileUpload}
                        beforeUpload={onBeforeUpload}
                        onChange={(e) => {
                            setCheckFile(e.fileList.length);
                        }}
                        onRemove={onRemove}
                        multiple={true}
                        accept=".jpg, .jpeg, .png"
                    >
                        <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Chọn ảnh</div>
                        </div>
                    </Upload>
            </Modal>
        </div>
    )
}
