import { useState, useRef } from "react";
import {
  Button,
  Form,
  Input,
  message,
} from "antd";
import { api } from "src/services";
function SampleOrderTestModal({
  handleCancelModal,
  resultChecked,
  packetItem,
  sampling,
  fetchSampleOrderTest
}) {
  const formRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const handleCreateSampleOrderTest = async (filedValues: any) => {
    if (resultChecked.length <= 0) {
      message.error("Vui lòng chọn chẩn đoán");
      return;
    }
    const dataSubmit = {
      name: filedValues.name,
      packet_test_id: packetItem,
      sampling_package_id: sampling,
      type_tests: resultChecked,
    };
    try {
      setLoading(true);
      const response: any = await api.createSampleOrderTest(dataSubmit);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      handleCancelModal();
      fetchSampleOrderTest();
    }
  };
  return (
    <div className="admin-table orderTest_form">
      <Form
        onFinish={handleCreateSampleOrderTest}
        ref={formRef}
        className="form-sample-order-test"
      >
        <Form.Item
          className="item_sample_ordertest"
          label="Tên chỉ định xét nghiệm mẫu"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={loading} htmlType="submit">
            Lưu
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SampleOrderTestModal;
