import { ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Image,
  Modal,
  Space,
  Table,
  Upload,
  message,
} from "antd";
import { useEffect, useState } from "react";
import {
  BreadcrumbComponent,
  IconDelete,
  ModalDelete,
  ModalUpdate,
  OnDeleteButton,
} from "src/components";
import { api } from "src/services";
import { SignatureProperties } from "src/types";

function DrSignature() {
  const [loading, setLoading] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);
  const [formDelete] = Form.useForm();
  const [formChangeStatus] = Form.useForm();
  const { confirm } = Modal;
  let formData = new FormData();
  const [dataSignature, setDataSignatures] = useState<SignatureProperties[]>(
    []
  );

  const fetchSignature = async () => {
    try {
      setLoading(true);
      const { data: rData }: { data: SignatureProperties[] } =
        await api.getSignatures();
      if (rData) {
        let signatureArr = rData.map((el: any) => ({
          key: el.id,
          ...el,
        }));
        setDataSignatures(signatureArr);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSignature();
  }, []);

  function handleCancel() {
    setModalDelete(false);
    setModalChangeStatus(false);
  }

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows.map((el) => el.id));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows.map((el) => el.id));
    },
  };

  const handleSubmit = async () => {
    if (fileUpload == null) {
      return message.error("Vui lòng chọn lại file Ảnh");
    }
    try {
      setLoading(true);
      formData.append("image", fileUpload);
      const response: any = await api.createSignature(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      setFileUpload(null);
      fetchSignature();
      setLoading(false);
    }
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteSignature(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      fetchSignature();
      handleCancel();
      setLoading(false);
    }
  };

  const onConfirmDelete = () => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa chữ ký này không",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const response: any = await api.deleteAllSignature(selectedRows);
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
          fetchSignature();
          setSelectedRows([]);
        }
      },

      onCancel() {
        console.log("Cancel delete");
      },
    });
  };

  const onBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      setFileUpload(null);
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }

    setFileUpload(file);
    return false;
  };

  const handleChangeStatus = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.updateSignature(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleCancel();
      fetchSignature();
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      key: "image",
      render: (text: string, record: any) => (
        <Image
          preview={{
            mask: <EyeOutlined style={{ color: "white" }} />,
            maskClassName: "hide-preview-text",
          }}
          src={record.image.url}
          width={40}
          height={35}
          alt="Avatar"
        />
      ),
    },
    {
      title: "Đang sử dụng",
      dataIndex: "active",
      key: "active",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Checkbox
            checked={record.active}
            onClick={() => handleClickCheckBox(record)}
          ></Checkbox>
        </Space>
      ),
    },
    {
      title: "Ngày",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Hành động",
      render: (text: string, record: any) => (
        <div>
          {record.active ? null : (
            <IconDelete onClick={() => handleClickDelete(record)} />
          )}
        </div>
      ),
    },
  ];

  const handleClickCheckBox = (record) => {
    setModalChangeStatus(true);
    formChangeStatus.setFieldsValue({
      id: record.id,
    });
  };

  const handleClickDelete = (record) => {
    setModalDelete(true);
    formDelete.setFieldsValue({
      id: record.id,
      status: record.status,
    });
  };

  const breadcrumbItems = [
    { path: "/", name: "Trang chủ" },
    { path: "#", name: "Cài đặt signature" },
  ];

  return (
    <div className="admin-table signature">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: ".5rem",
          }}
        >
          <div>
            <OnDeleteButton
              onClick={onConfirmDelete}
              disabled={selectedRows.length === 0}
              title={"Xóa"}
            />
          </div>
          <Upload
            onChange={handleSubmit}
            beforeUpload={onBeforeUpload}
            customRequest={() => {}}
            showUploadList={null}
            accept=".jpg, .jpeg, .png"
          >
            <Button type="primary">Upload File</Button>
          </Upload>
        </div>
        <Table
          rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={dataSignature}
          loading={loading}
          // pagination={tableParams.pagination}
          // onChange={handleTableChange}
        />
      </div>
      <ModalDelete
        open={modalDelete}
        cancel={handleCancel}
        form={formDelete}
        handleSubmit={handleDelete}
        loading={loading}
        title={"Xóa chữ ký"}
      />
      <ModalUpdate
        open={modalChangeStatus}
        cancel={handleCancel}
        form={formChangeStatus}
        handleSubmit={handleChangeStatus}
        loading={loading}
        title={"Thay đổi chữ ký"}
      />
    </div>
  );
}
export default DrSignature;
