import { FilePdfOutlined } from "@ant-design/icons";
import { List, Tag, Card, Image, Avatar, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/services";

function ClinicalNotesDetail(props) {
  const { number, subclinicalsId, patient_id } = props;
  const [loading, setLoading] = useState(false);
  const [sublinical, setSublinical] = useState([]);
  let { id }: any = useParams();

  useEffect(() => {
    fetchData();
  }, [number]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: rPatitent }: any = await api.getSubclinicalsDetail(subclinicalsId, id ? id : patient_id);
      if (rPatitent) {
        setSublinical(rPatitent?.result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      title="Kết quả"
      size="small"
      loading={loading}
      style={{ marginBottom: 16 }}
    >
      { sublinical && sublinical.length > 0 ? (
        <List
          className="list-detail-subclinical"
          dataSource={sublinical}
          renderItem={(item: any) => (
            <List.Item className="list-detail-subclinical-item">
              <div className="list-detail-subclinical-file">
                {
                  item.file && item.file.length ? item.images.map((image: any) => (
                    <div className="tag-subclinical">
                      <div className="subclinical-box">
                        <Row align="middle">
                          <Col span={6}>
                            <Image
                              width={38}
                              height={38}
                              style={{ objectFit: 'cover' }}
                              src={image.url}
                            />
                          </Col>
                          <Col span={10}>
                            <Avatar src={item?.doctor?.avatar}/> {item?.doctor?.name}
                          </Col>
                          <Col span={6}>
                            {item.day}, tháng {item.month}, {item.year}
                          </Col>
                          <Col span={2} className="center">
                            {item.time}
                          </Col>
                        </Row>
                      </div>
                    </div>
                )) : ''}
              </div>
              <div className="list-detail-subclinical-file">
                {
                  item.file && item.file.length ? item.file.map((pdf: any) => (
                    <div className="tag-subclinical">
                      <a href={pdf.url} target="__blank" className="subclinical-box">
                        <Row align="middle">
                          <Col span={6}>
                            <FilePdfOutlined />
                          </Col>
                          <Col span={10}>
                            <Avatar src={item?.doctor?.avatar}/> {item?.doctor?.name}
                          </Col>
                          <Col span={6}>
                            {item.day}, tháng {item.month}, {item.year}
                          </Col>
                          <Col span={2} className="center">
                            {item.time}
                          </Col>
                        </Row>
                      </a>
                    </div>
                )) : ''}
              </div>
            </List.Item>
          )}
        />
      ) : (
        <div>
          Không tìm thấy thông tin
        </div>
      )}
    </Card>
  );
}

export default ClinicalNotesDetail;
