import { useState } from "react";
import { Button, Card, Col, Form, Image, Row, Tag } from "antd";
import { EyeOutlined, FileAddOutlined, HistoryOutlined, WechatOutlined,ExclamationCircleOutlined } from "@ant-design/icons";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { ListDoctorModal, ListDocumentModal, ListRecentActivityModal } from "src/components";
import SpanPhone from "../shared/span/SpanPhone";
import moment from "moment";
import { useDispatch } from "react-redux";
import { allActions } from "src/redux";

PatientInformation.propTypes = {};

function PatientInformation({ patient, loading }) {
  const [isTextAreaVisible, setIsTextAreaVisible] = useState(false);
  const [isModalOpenDetail, setIsOpenModal] = useState(false);
  const [isOpenModalDocument, setIsOpenModalDocument] = useState(false);
  const [isOpenModalRecentActivity, setIsOpenModalRecentActivity] = useState(false);
  const [formCreate] = Form.useForm();

  const dispatch = useDispatch();

  const dateFormat = "DD-MM-YYYY";

  const handleChat = async(user:any)=>{
    dispatch(allActions.option.saveOption(user));
  }

  return (
    <Card
      title={<div className="font-bold">Thông tin bệnh nhân </div>}
      loading={loading}
      bordered={true}
    >
      <Row className="patient-information-content" style={{marginBottom:4}}>
        <Col className="patient-information-content-avatar">
          <Image
            src={
              patient?.member?.avatar
                ? patient?.member?.avatar
                : patient?.avatar
            }
            style={{ width: "4.125rem", height :  "4.125rem" }}
            preview={{
              mask: <EyeOutlined />,
              maskClassName: "hide-preview-text",
            }}
          />
        </Col>
        <Col className="patient-information-content-info">
          <Row style={{ marginBottom: "2px" }}>
            <Col span={10}>
              <div className="info-group">
                <div
                  className="info-group-title"
                  style={{ fontWeight: "bold" }}
                >
                  Họ và tên:
                </div>
                {patient?.name ? (
                  <div className="info-group-content">{patient.name}</div>
                ) : null}
              </div>
            </Col>
            <Col span={8}>
              <div className="info-group">
                <div
                  className="info-group-title"
                  style={{ fontWeight: "bold" }}
                >
                  Giới tính:
                </div>
                {patient?.gender ? (
                  <div className="info-group-content">
                    {patient.gender === "male" ? "Nam" : "Nữ"}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col span={6}>
              <Button
                disabled={patient.id ? false : true}
                className="btn-intro-dr"
                size="small"
                type="primary"
                style={{ width: "100%" }}
                icon={<FaRegShareFromSquare />}
                onClick={() => {
                  setIsOpenModal(true);
                  formCreate.resetFields();
                  setIsTextAreaVisible(false);
                }}
              >
                Giới thiệu bác sĩ
              </Button>
            </Col>
          </Row>
          <Row style={{ marginBottom: "2px" }}>
            <Col span={10}>
              <div className="info-group">
                <div
                  className="info-group-title"
                  style={{ fontWeight: "bold" }}
                >
                  Số điện thoại:
                </div>
                {patient?.phone ? (
                  <div className="info-group-content">
                    {<SpanPhone phone={patient.phone} />}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col span={8}>
              <div className="info-group">
                <div
                  className="info-group-title"
                  style={{ fontWeight: "bold" }}
                >
                  Ngày sinh:
                </div>
                {patient?.dob ? (
                  <div className="info-group-content">
                    {moment(patient.dob).format(dateFormat)}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col span={6}>
              <Button
                disabled={patient.id ? false : true}
                className="btn-document-picture"
                size="small"
                type="default"
                style={{ width: "100%" }}
                icon={<FileAddOutlined />}
                onClick={() => {
                  setIsOpenModalDocument(true);
                }}
              >
                Tài liệu và hình ảnh
              </Button>
            </Col>
          </Row>

          <Row style={{ marginBottom: "2px" }}>
            <Col span={14}>
              <div className="info-group">
                <div className="info-group-title-address" style={{ fontWeight: "bold" }}>Địa chỉ:</div>
                {patient?.address ? (
                  <div className="info-group-content-address">
                    {patient.address}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col span={4}>
              <Button
                disabled={patient.id ? false : true}
                className="btn-intro-dr"
                size="small"
                type="primary"
                title="Liên hệ"
                onClick={()=> handleChat(patient)}
              >
                Liên hệ
              </Button>
            </Col>
            <Col span={6}>
              <Button
                disabled={patient.id ? false : true}
                className="btn-recent-activity"
                size="small"
                type="default"
                style={{ width: "100%" }}
                icon={<HistoryOutlined />}
                onClick={() => setIsOpenModalRecentActivity(true)}
              >
                Hoạt động gần đây
              </Button>
            </Col>
          </Row>

        </Col>
      </Row>
      <ListDoctorModal
        patient_id={patient.id}
        setIsOpenModal={setIsOpenModal}
        setIsTextAreaVisible={setIsTextAreaVisible}
        isModalOpenDetail={isModalOpenDetail}
        isTextAreaVisible={isTextAreaVisible}
        formCreate={formCreate}
      />
      <ListDocumentModal
        open={isOpenModalDocument}
        setIsOpenModalDocument={setIsOpenModalDocument}
        patient_id={patient.id}
      />
      {
        isOpenModalRecentActivity &&
        <ListRecentActivityModal
          patient_id={patient.id}
          open={isOpenModalRecentActivity}
          setIsOpenModalRecentActivity={setIsOpenModalRecentActivity}
        />
      }
    </Card>
  );
}

export default PatientInformation;
