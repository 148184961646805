import {
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Radio,
  Space,
  Button,
  Checkbox,
  Collapse,
  Skeleton,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { utils } from "src/utils";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { PacketTestType, resultCheckType } from "src/types";
import { isEmpty } from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { sum } from "lodash";
function DetailSampleOrderTestModal({
  isModalUpdateVisible,
  handleCancel,
  sampleOrderTest,
  fetchSampleOrderTest,
}) {
  const [packetTests, setPacketTests] = useState<PacketTestType[]>([]);
  const [formRef] = Form.useForm();
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [samplingPackages, setSamplingPackages]: any = useState([]);
  const [selectedSamplingId, setSelectedSamplingId] = useState(null);
  const [loadingOrdertest, setLoadingOrdertest] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [searchQuery, setSearchQuery]: any = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [resultCheck, setResultCheck] = useState<resultCheckType[]>([]);
  const [typeTextErrorMessage, setTypeTextErrorMessage] = useState("");
  const formData = new FormData();

  const fetchTestPriority = async () => {
    try {
      const { data: rData }: { data: PacketTestType[] } =
        await api.getTestPriority();
      if (rData) {
        setPacketTests(rData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTestPlace = async () => {
    try {
      const { data: rData }: { data: PacketTestType[] } =
        await api.getTestPlace();
      if (rData) {
        setSamplingPackages(rData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTestCategories = async () => {
    try {
      const { data: rData }: any = await api.getAllTestCategories(searchQuery);
      if (rData) {
        setData(rData);
        setFilteredData(rData);
      }
    } catch (error) {
      message.error(error);
    }
  };
  const handleRadioChange = (e) => {
    setSelectedPackageId(e.target.value);
  };
  const handleSamplingRadioChange = (e) => {
    setSelectedSamplingId(e.target.value);
  };
  const handleCheckboxChange = (checkedValues) => {
    const isChecked = selectedTests.includes(checkedValues.id);
    if (isChecked == true) {
      const removerItem = resultCheck.filter(
        (item) => Number(item.id) !== Number(checkedValues.id)
      );
      setResultCheck(removerItem);
    } else {
      setResultCheck([...resultCheck, checkedValues]);
    }
    const newSelected = isChecked
      ? selectedTests.filter(
          (selectedTest) => selectedTest !== Number(checkedValues.id)
        )
      : [...selectedTests, Number(checkedValues.id)];
    setSelectedTests(newSelected);
    setTypeTextErrorMessage("");
  };
  const handleRemove = (e) => {
    const removerItem = resultCheck.filter((item, i) => Number(item.id) !== e);
    setSelectedTests(removerItem.map((el: any) => Number(el.id)));
    setResultCheck(removerItem);
  };
  //  Submit form
  const handleSubmit = async (values) => {
    console.log(values);

    if (resultCheck.length <= 0) {
      setTypeTextErrorMessage("Vui lòng chọn ít nhất một loại xét nghiệm");
      return;
    }
    try {
      setIsLoading(true);
      const response: any = await api.updatedSampleOrderTest(
        sampleOrderTest.id,
        {
          name: values.name,
          packet_test_id: values.packet_test_id,
          sampling_package_id: values.sampling_package_id,
          type_tests: resultCheck.map((el: any) => el),
        }
      );
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleCancel();
      setSelectedTests([]);
      setIsLoading(false);
      setResultCheck([]);
      fetchSampleOrderTest();
    }
  };
  // search
  const handleSearch = () => {
    // const filteredResults = data.filter((parent) => {
    //   const matchedChildren = parent.type_tests.filter((child) =>
    //     child.name.toLowerCase().includes(filterValue.toLowerCase())
    //   );
    //   return matchedChildren.length > 0;
    // });
    // setFilteredData(filteredResults);

    // New search
    const filteredResults = data.map((parent) => {
      // Lọc các con khớp với filterValue
      const matchedChildren = parent.type_tests.filter((child) =>
        child.name.toLowerCase().includes(filterValue.toLowerCase())
      );

      // Nếu có con khớp, trả về cha với danh sách con khớp
      if (matchedChildren.length > 0) {
        return { ...parent, type_tests: matchedChildren };
      }

      // Nếu không có con khớp, bỏ qua phần tử cha này
      return null;
    }).filter((parent) => parent !== null); // Loại bỏ các mục cha không khớp
  
    setFilteredData(filteredResults);
  };
  useEffect(() => {
    handleSearch();
  }, [filterValue]);

  useEffect(() => {
    fetchTestPriority();
    fetchTestPlace();
    fetchTestCategories();
    setSelectedTests(
      sampleOrderTest?.type_tests.map((el: any) => Number(el.id))
    );
    setResultCheck(sampleOrderTest.type_tests);
    setSelectedPackageId(sampleOrderTest.packet_test_id);
    setSelectedSamplingId(sampleOrderTest.sampling_package_id);
  }, []);
  const { Panel } = Collapse;
  return (
    <Modal
      title="Thông tin chi tiết chỉ định xét nghiệm mẫu"
      open={isModalUpdateVisible}
      footer={null}
      onCancel={handleCancel}
      width={1400}
      centered
      className="sample-prescription-add"
    >
      <div className="admin-table OrderTest">
        <div className="flex-fill">
          <Form form={formRef} onFinish={handleSubmit}>
            <Row gutter={[16, 16]}>
              <Col span={15}>
                <Card
                  className="card-order-left"
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <Form.Item
                    className="item_sample_ordertest"
                    label="Tên chỉ định xét nghiệm mẫu"
                    name="name"
                  >
                    <Input defaultValue={sampleOrderTest.name} />
                  </Form.Item>
                  <div className="order-content order-content_wapper package_test_wrapper">
                    {packetTests.length > 0 && (
                      <Form.Item
                        name="packet_test_id"
                        label="Gói xét nghiệm"
                        rules={[
                          {
                            required: true,
                            message: "Gói xét nghiệm không được bỏ trống",
                          },
                        ]}
                        initialValue={selectedPackageId}
                      >
                        <Radio.Group
                          defaultValue={selectedPackageId}
                          buttonStyle="solid"
                        >
                          {packetTests.map((item) => (
                            <Radio.Button
                              key={item.id}
                              value={item.id}
                              style={{
                                marginBottom: "1rem",
                                width: "100%",
                                background:
                                  selectedPackageId === item.id
                                    ? item.color
                                    : "initial",
                              }}
                              onChange={handleRadioChange}
                            >
                              {item.name} - {item.price_formated}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    )}
                    <div className="location_test">
                      {samplingPackages.length > 0 && (
                        <Form.Item
                          label="Xét nghiệm tại : "
                          name="sampling_package_id"
                          rules={[
                            {
                              required: true,
                              message:
                                "Địa điểm xét nghiệm không được bỏ trống",
                            },
                          ]}
                          initialValue={selectedSamplingId}
                        >
                          <Radio.Group
                            defaultValue={selectedSamplingId}
                            buttonStyle="solid"
                          >
                            {samplingPackages.map((item) => (
                              <Radio.Button
                                key={item.id}
                                value={item.id}
                                style={{
                                  marginBottom: "1rem",
                                  width: "100%",
                                  background:
                                    selectedSamplingId === item.id
                                      ? item.color
                                      : "initial",
                                }}
                                onChange={handleSamplingRadioChange}
                                disabled={item.id === 5}
                              >
                                {item.name} - {item.price_formated}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      )}
                    </div>
                  </div>
                </Card>

                <Card
                  size="small"
                  className="call_to_action"
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      loading={isLoading}
                      htmlType="submit"
                    >
                      Lưu
                    </Button>
                  </Form.Item>
                </Card>
              </Col>
              <Col span={9}>
                <Card
                  title="Loại xét nghiệm"
                  style={{
                    marginBottom: 16,
                    height: "unset",
                  }}
                  size="small"
                >
                  <Form.Item>
                    <Input
                      placeholder="Tìm kiếm danh mục"
                      onChange={(e) => setFilterValue(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Skeleton loading={loadingOrdertest} active>
                      <div className="scroll-checkbox">
                        {/* <Collapse> */}
                          {filteredData.map((cat, index) => (
                            // <Panel header={cat.name} key={index}>
                              <Row>
                                {cat.type_tests.map((test) => (
                                  <Col
                                    key={test.id}
                                    span={24}
                                    style={{ marginBottom: 5 }}
                                  >
                                    <Checkbox
                                      // checked={checkId.includes(test.id)}
                                      checked={selectedTests.some(
                                        (item) => item === test.id
                                      )}
                                      onClick={() => handleCheckboxChange(test)}
                                    >
                                      {`${
                                        test.name
                                      } - ${test.price.toLocaleString(
                                        "vi-VN"
                                      )} đ`}
                                    </Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            // </Panel>
                          ))}
                        {/* </Collapse> */}
                      </div>
                    </Skeleton>
                    <label style={{ color: "red" }}>
                      {!isEmpty(typeTextErrorMessage)
                        ? typeTextErrorMessage
                        : ""}{" "}
                    </label>
                  </Form.Item>
                </Card>
                <Card style={{ height: "unset", marginBottom: "1rem" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      marginBottom: "1rem",
                    }}
                  >
                    Loại xét nghiệm được chọn
                  </div>
                  <div>
                    <ul
                      style={{
                        maxHeight: "100px",
                        overflow: "auto",
                      }}
                    >
                      {resultCheck.map((test, index) => (
                        <li className="result__test-type" key={Number(test.id)}>
                          <span
                            onClick={() => handleRemove(Number(test.id))}
                            className="result__test-type-icon"
                          >
                            <CloseOutlined />
                          </span>
                          {`${test.name} - ${Number(test.price).toLocaleString(
                            "vi-VN"
                          )} đ`}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default DetailSampleOrderTestModal;
