import { Button, Card, Checkbox, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import Parser from "html-react-parser";
import { BreadcrumbComponent } from "src/components";

export default function TermsAndConditions() {
  const [listPatients, setListPatients] = useState("");
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: rPatitent }: any = await api.getTermsPolicy();
      if (rPatitent) {
        setListPatients(rPatitent);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const btnHandler = async () => {
    let formData = new FormData();
    try {
      setLoading(true);
      formData.append("accept_policy", "1");
      const response: any = await api.getCheckPolicy(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const breadcrumbItems = [
    { path: "/", name: "Trang chủ" },
    { path: "#", name: "Điều khoản & chính sách" },
  ];

  return (
    <div className="admin-table">
      <BreadcrumbComponent items={breadcrumbItems} />
      <div className="flex-fill">
        <Spin spinning={loading}>
          <Card>
            <div
              id="scrollableDiv"
              style={{
                height: 450,
                // width: 300,
                overflow: "auto",
                padding: "0 16px",
                // fontSize: "16px",
              }}
            >
              {Parser(listPatients)}
            </div>
          </Card>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: ".5rem",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginBottom: ".5rem",
                marginTop: ".5rem",
              }}
            >
              <Checkbox onChange={checkboxHandler}>
                Tôi đồng ý và chấp nhận các điều khoản
              </Checkbox>
            </div>
            <div>
              <Button
                type="primary"
                loading={loading}
                disabled={!agree}
                onClick={btnHandler}
              >
                Đồng ý
              </Button>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}
