import { Button, Card, Col, DatePicker, Form, message, Modal, Radio, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { PersonalInfo } from "src/components";
import { api } from "src/services";
import { utils } from "src/utils";

const ChangeScheduleBookingModal = ({ isOpenModal, handleCancel, formChange, booking, patientInfo, fetchListPatitent }) => {
    const [loading, setLoading] = useState(false);
    const [clinicSlots, setClinicSlots] = useState([]);
    const [selectedlSlot, setSelectedSlot] = useState(null);
    const [numberOrder, setNumberOrder] = useState(0);
    const [form]: any = Form.useForm();
    const dateFormatList = ['DD/MM/YYYY', 'YYYY-MM-DD'];
    const [slotDate, setSlotDate] = useState(moment(booking?.slot_date));
    const [disabledClinic, setDisabledClinic] = useState(true); // Vô hiệu hóa thời gian khám cũ

    const handleOnChangeClinicSlot = (slot: any, index: number) => {
        setSelectedSlot(slot);
        setNumberOrder(++index);
    }

    const handleOnChangeSlotDate = (date : any) => {
        let dateSelect = moment(date).format(dateFormatList[1]);
        setDisabledClinic(dateSelect === booking?.slot_date); 
        setSlotDate(date);
    }

    const fetchClinicSlots = async (service: any) => {
        try {
            setLoading(true);
            const { data: rData }: any = await api.getClinicSlot(service?.id, moment(slotDate).format(dateFormatList[1]));
            if (rData) {
                setClinicSlots(rData);
            }
        } catch (error) {
            console.log(error);
            message.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(slotDate){
            fetchClinicSlots(booking?.serivce);
        }
   }, [booking, slotDate])

    const handleClickSaveClinicSlot = async () => {
        let valuesForm = {
            address: patientInfo?.address,
            name: patientInfo?.name,
            dob: patientInfo?.dob,
            member_id: patientInfo?.id,
            email: patientInfo?.email,
            gender: patientInfo?.gender,
            phone: patientInfo?.phone,
            payment_confirm: booking?.payment_confirm?.value,
            payment_method: booking?.payment_method?.value,
            price: booking?.serivce?.price,
            service_id: booking?.serivce?.id,
            slot_date: moment(slotDate).format(dateFormatList[1]),
            slot_start_time: selectedlSlot?.start_time,
            slot_end_time: selectedlSlot?.end_time,
            slot_id: selectedlSlot?.id,
            status: 'pending',
            parent_booking_id: booking?.id,
        }

        try {
            setLoading(true);
            const response: any = await api.createBookingByDoctor(valuesForm);
            // Update status lịch booking cũ thành rescheduled
            const responseUpdate: any = await api.updateBookingStatus(booking?.id, "rescheduled");

            if (response.error && responseUpdate.error) {
                message.error(response.message);
                message.error(responseUpdate.message);
            } else {
                message.success('Yêu cầu thay đổi lịch khám bệnh thành công');
                form.resetFields();
                fetchListPatitent();
                handleCancel();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Modal
                title="Thay đổi lịch hẹn"
                open={isOpenModal}
                footer={null}
                onCancel={handleCancel}
                width={1400}
                centered
                className="modal-create-appointment"
            >
                <Spin tip="Loading..." spinning={loading} >
                    <Form
                        ref={formChange}
                        form={form}
                        name="validate_other"
                        onFinish={handleClickSaveClinicSlot}
                        initialValues={{ payment_method: 'cash', slot_date: slotDate }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={14}>
                                <Card
                                    className="card-order-left cart-appoiment-left"
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <div className="order-content order-content_wapper package_test_wrapper">
                                        <div className="packet_test">
                                            <div style={{ marginBottom: '10px' }}>
                                                <strong>Gói dịch vụ: </strong>
                                            </div>
                                            <span>
                                                {booking?.serivce?.name} - {utils.formatMoney(booking?.serivce?.price)}đ
                                            </span>
                                        </div>
                                        <div className="location_test">
                                            <div style={{ marginBottom: '10px' }}>
                                                <strong>Ngày khám: </strong>
                                            </div>
                                            <Form.Item
                                                name="slot_date"
                                                rules={[{ required: true, message: 'Vui lòng chọn ngày gian khám!' }]}
                                            >
                                                <DatePicker 
                                                    format={dateFormatList[0]} 
                                                    onChange={handleOnChangeSlotDate} 
                                                    disabledDate={utils.disabledDatePrevious}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Card>

                                {patientInfo &&
                                    <Card
                                        className="card-order-left cart-appoiment-patient-detail"
                                        style={{
                                            marginBottom: 16,
                                            height: "unset",
                                        }}
                                    >
                                        <PersonalInfo patientInfo={patientInfo} />
                                    </Card>
                                }

                                <Card
                                    className="card-order-left cart-appoiment-top"
                                >
                                    <Button type="primary" loading={loading} htmlType="submit">
                                        Lưu
                                    </Button>
                                </Card>
                            </Col>
                            <Col span={10}>
                                <Card
                                    className="card-order-left cart-appoiment-clinic-slot cart-appoiment-right"
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <div style={{ marginBottom: '10px' }}>
                                        <strong>Phương thức thanh toán: </strong> {booking?.payment_method?.label}
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <strong>Số thứ tự khám: </strong>0{numberOrder} (dự kiến)
                                    </div>
                                    <Form.Item
                                        name="clinic_slot_id"
                                        rules={[{ required: true, message: 'Vui lòng chọn thời gian khám!' }]}
                                    >
                                        {clinicSlots && clinicSlots.length > 0 && (
                                            <Radio.Group buttonStyle="solid">
                                                {clinicSlots.map((item, index) =>
                                                    <Radio.Button 
                                                        key={item?.id} 
                                                        value={item?.id} 
                                                        onChange={() => handleOnChangeClinicSlot(item, index)}
                                                        disabled={(item?.start_time === booking?.slot_start_time && disabledClinic) || item?.is_booked}
                                                    >
                                                        {item?.start_time} - {item?.end_time}
                                                    </Radio.Button>
                                                )}
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export default ChangeScheduleBookingModal;
