import { UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Input, List } from 'antd'
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react'

export default function ListChat({
    dataSource,
    adminChat,
    loading,
    userId,
    openChat,
    isLoadMore,
    loadMoreListChat,
    searchChatList,
    setSearchChatList
}) {
    /*  INIT VARIABLE  */
    const data = dataSource;
    
    /* HELPER FUNCTION */

    /**
     * Lấy dữ liệu người dùng từ members để tách người dùng chính và đối tượng chat
     * @param data 
     */
    const getUserTargetChat = ({ members }) =>{
        if(!members) return {};

        if(members[0]._id === userId){
            return members[1];
        }else {
            return members[0];
        }
    }

    const getTargetChatAvatar = (data:any) =>{
        const user:any = getUserTargetChat(data);
        return user.avatar;
    }

    const getTargetChatName = (data:any) =>{
        const user:any = getUserTargetChat(data);
        return user.name;
    }

    const getLastMessage = ({lastMessage}) =>{
        if(!lastMessage?.user) return "";
        if(!lastMessage.text){
            if(userId === lastMessage.user._id){
                return `Bạn đã gửi một ảnh`;
            }
            return `Đã gửi một ảnh`;
        }
        if(userId === lastMessage.user._id){
            return `Bạn: ${lastMessage.text}`;
        }
        return lastMessage.text;
    }

    const getLastTimeSeen = ({lastMessage}) =>{
        if(!lastMessage?.createdAt) return "";

        let timeAgo = moment(lastMessage.createdAt).fromNow();

        return timeAgo;
    }

    const isRead = ({is_read}) =>{
        return is_read === "1";
    }

    function showButtonLoadMore(i:number, max:number){
        return i === max && isLoadMore ?
            <div style={{display:'flex', justifyContent:'center',padding:'12px 0'}}>
                <Button type="primary" onClick={()=> loadMoreListChat()}>
                    Thêm
                </Button>
            </div>:
            <></> 
    }
    return (
        <div className='list-chat-wrapper'>
            <div className='mb-12'>
                <Input 
                    placeholder="Tìm kiếm"
                    onChange={_.debounce( async(event)=>{ 
                        await setSearchChatList(event.target.value)
                    },500)}
                    prefix={<UserOutlined />} 
                />
            </div>

            
            { adminChat ? 
                <div className='chat-item' style={{ cursor: "pointer" }}
                    onClick={()=> openChat( adminChat.id, <div className='header-message'> <Avatar src={getTargetChatAvatar(adminChat)}/> {getTargetChatName(adminChat)}</div>)}>
                    <List.Item style={{ width: "100%" }}>
                        <Avatar src={getTargetChatAvatar(adminChat)} />
                        <div className='chat-box'>
                            <div className="">
                                <div className="overflow-ellipsis">
                                    <strong >{getTargetChatName(adminChat)}</strong>
                                </div>
                                <div className="overflow-ellipsis">
                                    { 
                                        isRead(adminChat)  ? <span>{getLastMessage(adminChat)}</span>
                                        : <strong>{getLastMessage(adminChat)}</strong>
                                    }
                                </div>
                            </div>
                            <div className='time-group'>
                                { 
                                    isRead(adminChat) ? <span>{getLastTimeSeen(adminChat)}</span>
                                    : <>
                                        <span className='dot'></span>
                                        <strong>{getLastTimeSeen(adminChat)}</strong>
                                    </>
                                }
                            </div>
                        </div>
                    </List.Item>
                </div> : <></>}
                

            <List dataSource={dataSource}
                loading={loading}
                renderItem={(item: any, index:any) => {
                    return <>
                        <div className='chat-item' style={{ cursor: "pointer" }}
                            onClick={()=> openChat( item.id, <div className='header-message'> <Avatar src={getTargetChatAvatar(item)}/> {getTargetChatName(item)}</div>)}>
                            <List.Item style={{ width: "100%" }}>
                                <Avatar src={getTargetChatAvatar(item)} />
                                <div className='chat-box'>
                                    <div className="">
                                        <div className="overflow-ellipsis">
                                            <strong >{getTargetChatName(item)}</strong>
                                        </div>
                                        <div className="overflow-ellipsis">
                                            { 
                                                isRead(item)  ? <span>{getLastMessage(item)}</span>
                                                : <strong>{getLastMessage(item)}</strong>
                                            }
                                        </div>
                                    </div>
                                    <div className='time-group'>
                                        { 
                                            isRead(item) ? <span>{getLastTimeSeen(item)}</span>
                                            : <>
                                                <span className='dot'></span>
                                                <strong>{getLastTimeSeen(item)}</strong>
                                            </>
                                        }
                                    </div>
                                </div>
                            </List.Item>
                        </div>
                        { showButtonLoadMore(index, dataSource.length - 1) }
                    </>
                }}
            />
        </div>
    )
}
